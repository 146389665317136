import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import { clearData as clearAuthData } from "../store/Auth_User/authSlice";
import { showNotification } from "../store/Notification/notificationSlice";
import { clearUser } from "../store/Auth_User/userSlice";
import { clearCompetences } from "../store/Competences/competencesSlice";
import { clearAreas } from "../store/AiMatrix/aiAreasSlice";
import { clearBadges } from "../store/AiMatrix/aiBadgesSlice";
import { clearMatrix } from "../store/AiMatrix/createMatrixSlice";
import { clearMatrixes } from "../store/Matrixes/matrixesSlice";
import { clearData } from "../store/CurrentMatrix/currentMatrixSlice";

export const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(clearAuthData());
        dispatch(clearUser());
        dispatch(clearCompetences());
        dispatch(clearAreas());
        dispatch(clearBadges());
        dispatch(clearMatrix());
        dispatch(clearMatrixes());
        dispatch(clearData());

        navigate("/");

        dispatch(
            showNotification({
                variant: "success",
                title: "Sukces!",
                subtitle: "Wylogowano pomyślnie",
            }),
        );
    };

    return logout;
};
