import { useDrag } from "react-dnd";

import { Competence } from "../Competence/Competence";

import { isCompetence } from "../KanbanBoard";

import { CompetenceGoal } from "../CompetenceGoal/CompetenceGoal";

import { CardProps } from "./Card.types";
import styles from "./Card.module.scss";

export const Card = ({ item }: CardProps) => {
    const [, drag] = useDrag(() => ({
        type: "card",
        item: item,
    }));

    return (
        <div ref={drag} className={styles.container}>
            {isCompetence(item) ? <Competence item={item} /> : <CompetenceGoal goal={item} />}
        </div>
    );
};
