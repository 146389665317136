// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Counter_wrapper__YXsP1 {
  border: 1px solid #EAECF0;
  padding: 3px 9px;
  border-radius: 12px;
  font-weight: 500;
  size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Counter/Counter.module.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;AADJ","sourcesContent":["@use '../../styles/colors.scss' as colors; \r\n\r\n.wrapper {\r\n    border: 1px solid colors.$GRAY200;\r\n    padding: 3px 9px;\r\n    border-radius: 12px;\r\n    font-weight: 500;\r\n    size: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Counter_wrapper__YXsP1`
};
export default ___CSS_LOADER_EXPORT___;
