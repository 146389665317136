import { createSlice } from "@reduxjs/toolkit";

import { Competence } from "../../api/types";

export interface InfoModalState {
    isOpen: boolean;
    item: Competence | null;
}

export const initialState: InfoModalState = {
    isOpen: false,
    item: null,
};

const modalSlice = createSlice({
    name: "infoModal",
    initialState,
    reducers: {
        toggleModal: (state, action) => {
            state.isOpen = !state.isOpen;
            state.item = action.payload;
        },
        setText: (state, action) => {
            if (state.item?.additional_info) {
                state.item.additional_info = action.payload;
            }
        },
    },
});

export const { toggleModal, setText } = modalSlice.actions;
export default modalSlice.reducer;
