import React, { useState } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";

import * as Yup from "yup";

import { useDispatch } from "react-redux";

import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    checkboxClasses,
} from "@mui/material";

import { isAxiosError } from "axios";

import { useTranslation } from "react-i18next";

import { Button } from "../Button/Button";

import { Register } from "../../api/requests/requests";

import { showNotification } from "../../store/Notification/notificationSlice";

import { AppDispatch } from "../../store/store";

import { ReactComponent as Logo } from "../../assets/pngs/logo.svg";

import { RegisterCredentials } from "../../api/types";

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

import styles from "./RegisterForm.module.scss";

export const RegisterForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<Record<string, string[]>>();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        position: Yup.string().required(t("globals.validation.required")),
        first_name: Yup.string().required(t("globals.validation.required")),
        last_name: Yup.string().required(t("globals.validation.required")),
        email: Yup.string().required(t("globals.validation.required")).email(t("globals.validation.emailNotValid")),
        password: Yup.string().required(t("globals.validation.required")),
        acceptTerms: Yup.boolean().required(),
    });

    const handleSubmit = async (values: {
        position: string;
        first_name: string;
        last_name: string;
        email: string;
        password: string;
        companyInput: string;
        selectedOption: string;
        acceptTerms: boolean;
        acceptNewsletter: boolean;
    }) => {
        const registerData: RegisterCredentials = {
            position: values.position,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            accept_terms: values.acceptTerms,
            accept_newsletter: values.acceptNewsletter,
            ...(values.selectedOption === "option1"
                ? { organization_uuid: values.companyInput }
                : { organization: values.companyInput }),
        };
        setErrors(undefined);
        localStorage.removeItem("user");
        try {
            const res = await Register(registerData);
            if (res) {
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: "Rejestracja przebiegła pomyślnie, możesz się zalogować.",
                    }),
                );
                navigate("/login");
            }
        } catch (error) {
            if (isAxiosError(error) && Object.values(error?.response?.data)) {
                if (error?.response?.data.detail) {
                    dispatch(
                        showNotification({
                            variant: "error",
                            title: "Error!",
                            subtitle: error?.response?.data.detail,
                        }),
                    );
                } else {
                    setErrors(error?.response?.data);
                }
            }
            console.log("Błąd rejestracji:", error);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.languageSwitcherWrapper}>
                <LanguageSwitcher />
            </div>
            <a href={process.env.REACT_APP_LANDING_PAGE}>
                <Logo className={styles.logo} />
            </a>
            <Formik
                initialValues={{
                    position: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    showPassword: false,
                    selectedOption: "option1",
                    companyInput: "",
                    acceptTerms: false,
                    acceptNewsletter: false,
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, values, handleChange }) => (
                    <Form className={styles.form}>
                        <p className={styles.title}>{t("registerPage.register")}</p>
                        <FormControl component="fieldset">
                            <RadioGroup
                                sx={{ display: "flex", gap: "8px" }}
                                aria-label="selectedOption"
                                name="selectedOption"
                                value={values.selectedOption}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="option1"
                                    control={
                                        <Radio
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                        />
                                    }
                                    label={t("registerPage.join.text")}
                                    sx={{
                                        height: "20px",
                                        border:
                                            values.selectedOption === "option1"
                                                ? "2px solid #7F56D9"
                                                : "2px solid #EAECF0",
                                        borderRadius: "12px",
                                        padding: "14px",
                                        margin: "0",
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "14px",
                                            color: "#344054",
                                            fontWeight: 500,
                                        },
                                    }}
                                />
                                <FormControlLabel
                                    value="option2"
                                    control={
                                        <Radio
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                        />
                                    }
                                    label={t("registerPage.makeNew.text")}
                                    sx={{
                                        height: "20px",
                                        border:
                                            values.selectedOption === "option2"
                                                ? "2px solid #7F56D9"
                                                : "2px solid #EAECF0",
                                        borderRadius: "12px",
                                        padding: "14px",
                                        margin: "0",
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "14px",
                                            color: "#344054",
                                            fontWeight: 500,
                                        },
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>

                        <Field name="companyInput" data-testid="companyInput">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="company-label" className={styles.label}>
                                        {values.selectedOption === "option1"
                                            ? t("registerPage.join.label")
                                            : t("registerPage.makeNew.label")}
                                    </InputLabel>
                                    <TextField
                                        id="company-label"
                                        {...field}
                                        placeholder={t("registerPage.join.placeholder")}
                                        variant="outlined"
                                        required
                                        error={
                                            (meta.touched && Boolean(meta.error)) ||
                                            Boolean(errors?.["organization_uuid"])
                                        }
                                        helperText={
                                            (meta.touched && meta.error) || (
                                                <ul className={styles.fieldErrors}>
                                                    {errors?.["organization_uuid"]?.map((errorMessage, index) => (
                                                        <li key={index} className={styles.errorMessage}>
                                                            {errorMessage}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )
                                        }
                                        FormHelperTextProps={{ component: "div" }}
                                        value={values.companyInput}
                                        onChange={handleChange}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="position" data-testid="position">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="position-label" className={styles.label}>
                                        {t("registerPage.position")}
                                    </InputLabel>
                                    <TextField
                                        id="position-label"
                                        {...field}
                                        required
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="first_name" data-testid="first_nameInput">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="first_name-label" className={styles.label}>
                                        {t("registerPage.firstName")}
                                    </InputLabel>
                                    <TextField
                                        id="first_name-label"
                                        {...field}
                                        required
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="last_name" data-testid="last_nameInput">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="last_name-label" className={styles.label}>
                                        {t("registerPage.lastName")}
                                    </InputLabel>
                                    <TextField
                                        id="last_name-label"
                                        {...field}
                                        required
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="email" data-testid="email">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="email-label" className={styles.label}>
                                        {t("registerPage.email")}
                                    </InputLabel>
                                    <TextField
                                        {...field}
                                        required
                                        error={(meta.touched && Boolean(meta.error)) || Boolean(errors?.["email"])}
                                        helperText={
                                            (meta.touched && meta.error) || (
                                                <ul className={styles.fieldErrors}>
                                                    {errors?.["email"]?.map((errorMessage, index) => (
                                                        <li key={index} className={styles.errorMessage}>
                                                            {errorMessage}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )
                                        }
                                        FormHelperTextProps={{ component: "div" }}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="password" data-testid="passwordInput">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="password-label" className={styles.label}>
                                        {t("registerPage.password")}
                                    </InputLabel>
                                    <TextField
                                        {...field}
                                        id="password-label"
                                        required
                                        type="password"
                                        error={(meta.touched && Boolean(meta.error)) || Boolean(errors?.["password"])}
                                        helperText={
                                            (meta.touched && meta.error) || (
                                                <ul className={styles.fieldErrors}>
                                                    {errors?.["password"]?.map((errorMessage, index) => (
                                                        <li key={index} className={styles.errorMessage}>
                                                            {errorMessage}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )
                                        }
                                        FormHelperTextProps={{ component: "div" }}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="acceptTerms">
                            {({ field }: FieldProps) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            sx={{
                                                display: "flex",
                                                alignSelf: "flex-start",
                                                padding: "3px",
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: "16px",
                                                },
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                            required
                                        />
                                    }
                                    label={
                                        <span className={styles.termsLabel}>
                                            {t("registerPage.termsLabel.first")}{" "}
                                            <a
                                                className={styles.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://outcomeskills.com/polityka-prywatnosci/"
                                            >
                                                {t("registerPage.termsLabel.privacyPolicy")}
                                            </a>{" "}
                                            {t("registerPage.termsLabel.second")}{" "}
                                            <a className={styles.link} href="https://outcomeskills.com/regulamin/">
                                                {t("registerPage.termsLabel.terms")}
                                            </a>{" "}
                                            {t("registerPage.termsLabel.third")}
                                        </span>
                                    }
                                />
                            )}
                        </Field>
                        <Field name="acceptNewsletter">
                            {({ field }: FieldProps) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            sx={{
                                                display: "flex",
                                                alignSelf: "flex-start",
                                                padding: "3px",
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: "16px",
                                                },
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <span className={styles.termsLabel}>{t("registerPage.newseletterLabel")}</span>
                                    }
                                />
                            )}
                        </Field>
                        <div className={styles.registerButtonContainer}>
                            {isSubmitting ? (
                                <CircularProgress />
                            ) : (
                                <Button type="submit" variant="secondary" size="medium" fullWidth>
                                    {t("registerPage.register")}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            <p className={styles.loginText}>
                {t("registerPage.scentence")}{" "}
                <RouterLink to="/login" className={styles.loginLink}>
                    {t("globals.login")}
                </RouterLink>
            </p>
        </div>
    );
};
