import React, { FC } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { ReactComponent as Trophy } from "../../assets/icons/24x24/trophy.svg";
import { ReactComponent as Profile } from "../../assets/icons/24x24/profile.svg";

import { RootState } from "../../store/store";

import { PageHeader } from "../../components/PageHeader/PageHeader";

import styles from "./MatrixSummary.module.scss";

export const MatrixSummary: FC = () => {
    const { id } = useSelector((state: RootState) => state.currentMatrix);
    const { user } = useSelector((state: RootState) => state.userStore);
    const { t } = useTranslation();

    if (!id) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftWrapper}>
                <PageHeader title={`${t("globals.welcome")} ${user.first_name} ${user.last_name}`} />
            </div>
            <div className={styles.midWrapper}>
                {/* <SectionHeader title="12/20 kompetencji" subTitle="Wymaganych na poziomie: Mid" icon={<Trophy />} />
                <SectionHeader title="4 kompetencje" subTitle="Brakujące do poziomu: Senior" icon={<Profile />} /> */}
                {/* https://pluckyrebels.atlassian.net/jira/software/projects/CE/boards/4?selectedIssue=CE-803 */}
            </div>
            <div className={styles.rightWrapper}></div>
        </div>
    );
};
