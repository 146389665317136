import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";

import { Competence, CompetenceGoal, UserGoal } from "../../api/types";

import { moveCompetence, moveUserGoal } from "../../store/Kanban/Columns/Columns.slice";

import styles from "./KanbanBoard.module.scss";

import { Column } from "./Column/Column";
import { Item } from "./KanbanBoard.types";

const Backend = isMobile ? TouchBackend : HTML5Backend;

export const isCompetence = (item: Competence | UserGoal): item is Competence => {
    return (item as Competence).level !== undefined;
};

export const KanbanBoard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { columns, loading } = useSelector((state: RootState) => state.columns);
    const { currentEmployeeId } = useSelector((state: RootState) => state.employeesManagment);
    const [items, setItems] = useState<Item[]>([]);

    const onColumnChange = (item: Competence | CompetenceGoal, columnId: number) => {
        isCompetence(item)
            ? dispatch(moveCompetence({ columnId: columnId, competence: item, userId: currentEmployeeId }))
            : dispatch(moveUserGoal({ order: columnId, goalId: item.pk as number, userId: currentEmployeeId }));
    };

    const handleDelete = (item: Competence) => {
        if (!item.pk) return;
        const newItems = items.filter((prevItem) => prevItem.id !== item.pk?.toString());
        setItems(newItems);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <DndProvider backend={Backend}>
            <div className={styles.container}>
                <div className={styles.columnsContainer}>
                    {columns.map((column, i) => {
                        return (
                            <Column
                                column={column}
                                key={i}
                                onColumnChange={onColumnChange}
                                onNewItem={(title: string) => {
                                    setItems([
                                        ...items,
                                        {
                                            id: Math.random().toString(),
                                            title,
                                            column: column.matrixes[0].table_column_pk,
                                            row: null,
                                        },
                                    ]);
                                }}
                                handleDelete={handleDelete}
                            />
                        );
                    })}
                </div>
                {/* <div className={styles.column}>
                    <TitleInput
                        placeholder="Nazwa kolumny"
                        onSubmit={(title: string) => setColumns([...columns, { id: Math.random(), title }])}
                    />
                </div> */}
            </div>
        </DndProvider>
    );
};
