import React, { FC } from "react";

import { CreateMatrix } from "../../components/CreateMatrix/CreateMatrix";

import styles from "./CreatePage.module.scss";

export const CreatePage: FC = () => {
    return (
        <div className={styles.wrapper}>
            <CreateMatrix />
        </div>
    );
};
