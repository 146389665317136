// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KanbanBoard_container__L\\+rs7 {
  display: flex;
}

.KanbanBoard_columnsContainer__KUj3d {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  margin-top: 24px;
}

.KanbanBoard_column__xPgqr {
  border: 1px dashed gray;
  margin: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/KanbanBoard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".container {\r\n    display: flex;\r\n}\r\n\r\n.columnsContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 32px;\r\n    width: 100%;\r\n    margin-top: 24px;\r\n}\r\n\r\n.column {\r\n    border: 1px dashed gray;\r\n    margin: 16px;\r\n    padding: 16px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KanbanBoard_container__L+rs7`,
	"columnsContainer": `KanbanBoard_columnsContainer__KUj3d`,
	"column": `KanbanBoard_column__xPgqr`
};
export default ___CSS_LOADER_EXPORT___;
