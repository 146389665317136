import { configureStore } from "@reduxjs/toolkit";

import matrixReducer from "./Matrixes/matrixesSlice";
import modalReducer from "./Modal/modalSlice";
import infoModalReducer from "./Info/infoSlice";
import authReducer from "./Auth_User/authSlice";
import notificationReducer from "./Notification/notificationSlice";
import competencesReducer from "./Competences/competencesSlice";
import currentMatrixReducer from "./CurrentMatrix/currentMatrixSlice";
import aiBadgesReducer from "./AiMatrix/aiBadgesSlice";
import aiAreasReducer from "./AiMatrix/aiAreasSlice";
import employeeReducer from "./Employees/employeesSlice";
import userReducer from "./Auth_User/userSlice";
import aiMatrixReducer from "./AiMatrix/createMatrixSlice";
import currentTabReducer from "./CurrentTab/currentTabSlice";
import columnsReducer from "./Kanban/Columns/Columns.slice";
import goalsReducer from "./Kanban/Goals/Goals.slice";
import languageReducer from "./Language/languageSlice";
import lostAccountReducer from "./LostAccount/lostAccountSlice";
import coursesReducer from "./Courses/coursesSlice";
import templatesReducer from "./Templates/templatesSlice";
import employeesManagmentReducer from "./EmployeesManagmentPanel/EmployeesManagmentPanel.slice";

const store = configureStore({
    reducer: {
        matrixes: matrixReducer,
        modal: modalReducer,
        infoModal: infoModalReducer,
        auth: authReducer,
        notification: notificationReducer,
        competences: competencesReducer,
        currentMatrix: currentMatrixReducer,
        aiMatrix: aiMatrixReducer,
        aiBadges: aiBadgesReducer,
        aiAreas: aiAreasReducer,
        userStore: userReducer,
        employees: employeeReducer,
        currentTab: currentTabReducer,
        columns: columnsReducer,
        goals: goalsReducer,
        employeesManagment: employeesManagmentReducer,
        language: languageReducer,
        lostAccount: lostAccountReducer,
        templates: templatesReducer,
        courses: coursesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
