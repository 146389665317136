import React, { useState } from "react";
import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Button } from "../../Button/Button";

import { AddItemFormProps } from "./AddItemForm.types";
import styles from "./AddItemForm.module.scss";

export const AddItemForm: React.FC<AddItemFormProps> = ({ handleBadgeAdd, title }) => {
    const { t } = useTranslation();

    const [newItemName, setNewItemName] = useState("");
    const handleAddClick = () => {
        if (newItemName.trim() !== "") {
            handleBadgeAdd(newItemName);
            setNewItemName("");
        }
    };
    return (
        <div className={styles.wrapper}>
            <TextField
                sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#7F56D9",
                        },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: "#7F56D9",
                    },
                }}
                label={title}
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
            />
            <Button onClick={handleAddClick} type="button" variant="helper" size="small">
                {t("createModel.add")}
            </Button>
        </div>
    );
};
