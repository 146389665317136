import { Navigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

import { selectAccessToken } from "../store/Auth_User/authSlice";

interface ProtectedRouteProps {
    redirectPath?: string;
    children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = "/", children }) => {
    const accessToken = useSelector(selectAccessToken);
    if (!accessToken) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};
