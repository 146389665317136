import React, { FC, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { ReactComponent as Flag } from "../../../assets/icons/20x20/flag.svg";
import { ReactComponent as User } from "../../../assets/icons/16x16/user.svg";

import { Menu } from "../Menu/Menu";

import { HeaderProps } from "./Header.types";
import styles from "./Header.module.scss";

export const Header: FC<HeaderProps> = ({
    areas,
    menuActions,
    isMenuOpen = false,
    toogleMenuAction,
    toogleFlagAction,
    competence,
    flagMenu,
    isFlagMenuOpen = false,
}) => {
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>();

    const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!e) return;
        setAnchorEl(e.currentTarget);
    };

    const getColor = () => {
        switch (competence.learning_requirement_status) {
            case 0:
                return "NOT_REQUIRED_TO_KNOW";
            case 10:
                return "WANT_TO_KNOW";
            case 20:
                return "REQUIRED_TO_KNOW";
            default:
                return "";
        }
    };
    const isMenuAction = menuActions.length > 0 && toogleMenuAction;
    return (
        <div className={styles.wrapper}>
            <ul className={styles.areasContainer}>
                {areas.map((area, i) => (
                    <li key={i} data-testid="listitem" className={styles.area} style={{ color: area?.text_color }}>
                        <User />
                    </li>
                ))}
            </ul>
            <div className={styles.rightContainer}>
                {flagMenu && toogleFlagAction && (
                    <button
                        className={isMenuAction ? styles.firstActionItemWrapper : styles.actionItemWrapper}
                        onClick={(e) => {
                            toogleFlagAction();
                            handleMenuOpen(e);
                        }}
                        type="button"
                    >
                        <Flag className={styles[getColor()]} />
                        <Menu
                            actions={flagMenu}
                            toogleAction={toogleFlagAction}
                            isOpen={isFlagMenuOpen}
                            anchorEl={anchorEl}
                        />
                    </button>
                )}
                {isMenuAction && (
                    <>
                        <button
                            className={styles.actionItemWrapper}
                            onClick={(e) => {
                                toogleMenuAction();
                                handleMenuOpen(e);
                            }}
                        >
                            <MoreVertIcon />
                        </button>
                        <Menu
                            actions={menuActions}
                            toogleAction={toogleMenuAction}
                            isOpen={isMenuOpen}
                            anchorEl={anchorEl}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
