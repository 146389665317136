import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { Checkbox, CircularProgress, List, ListItem, ListItemButton, ListItemText, Menu } from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";

import { assignEmployees, unassignEmployees } from "../../store/Competences/competencesSlice";

import styles from "./EmployeeList.module.scss";
import { EmployeeListProps } from "./EmployeeList.types";

export const EmployeeList = ({ toggleAction, anchorEl, isOpen }: EmployeeListProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useSelector((state: RootState) => state.currentMatrix);
    const { employees, isAssigning } = useSelector((state: RootState) => state.competences);

    if (isAssigning) return <CircularProgress data-testid="circularProgress" />;

    return (
        <Menu sx={{ marginTop: "32px" }} open={isOpen} onClose={toggleAction} anchorEl={anchorEl} disableScrollLock>
            <List>
                {employees.map((employee) => {
                    return (
                        <ListItem disablePadding key={employee.email}>
                            <ListItemButton
                                component="button"
                                onClick={() =>
                                    id &&
                                    (employee.is_assigned_current_matrix
                                        ? dispatch(unassignEmployees({ matrixId: id, userEmail: employee.email }))
                                        : dispatch(assignEmployees({ matrixId: id, userEmail: employee.email })))
                                }
                            >
                                <ListItemText primary={employee.email} className={styles.matrixButton} />
                                {employee.is_assigned_current_matrix && <Checkbox edge="end" checked={true} />}
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Menu>
    );
};
