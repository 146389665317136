import React, { FC } from "react";
import { Tooltip } from "@mui/material";

import { TruncatedTextWithTooltipProps } from "./TruncatedTextWithTooltip.types";

const truncateText = (text: string, visibleWords: number): string => {
    let wordCount = 0;
    let truncatedText = "";
    const words = text.split(/(\s+|\n+)/);

    for (const part of words) {
        // Jeśli część to słowo
        if (part.trim().length > 0 && part !== "\n") {
            if (wordCount < visibleWords) {
                truncatedText += part;
                wordCount++;
            } else {
                break;
            }
        } else if (part === "\n") {
            if (wordCount < visibleWords) {
                truncatedText += "\n";
            } else {
                break;
            }
        } else {
            if (wordCount < visibleWords) {
                truncatedText += part;
            } else {
                break;
            }
        }
    }

    return truncatedText.trim();
};

export const TruncatedTextWithTooltip: FC<TruncatedTextWithTooltipProps> = ({
    text,
    visibleWords,
    bgcolor = "#F9F5FF",
    color = "#6941C6",
    fontSize = "16px",
    bgimage,
}) => {
    const truncatedText = truncateText(text, visibleWords);
    const remainingText = text.split(" ").slice(visibleWords).join(" ");

    return (
        <Tooltip
            title={<span style={{ whiteSpace: "pre-line" }}>{text}</span>}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: bgcolor,
                        backgroundImage: bgimage,
                        color: color,
                        fontSize: fontSize,
                        fontWeight: 500,
                        borderRadius: "8px",
                        padding: "16px",
                        "& .MuiTooltip-arrow": {
                            color: bgcolor,
                        },
                    },
                },
            }}
        >
            <span style={{ whiteSpace: "pre-line", textAlign: "left" }}>
                {truncatedText}
                {remainingText && "..."}
            </span>
        </Tooltip>
    );
};
