import React, { useEffect, useState } from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    const [displayedValue, setDisplayedValue] = useState<number>(0);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        const calculateEndValue = (value: number) => {
            if (value === 0) return 24;
            if (value <= 40) return value * 2;
            if (value === 66) return 98;
            if (value < 75) return 75;
            return 99;
        };

        const endValue = calculateEndValue(props.value);

        intervalId = setInterval(() => {
            setDisplayedValue((prev) => {
                if (props.value > prev) {
                    return props.value;
                }

                if (prev < endValue) {
                    return prev + 1;
                } else {
                    clearInterval(intervalId as NodeJS.Timeout);
                    return prev;
                }
            });
        }, 700);

        if (props.value === 100) {
            intervalId = setInterval(() => {
                setDisplayedValue((prev) => {
                    if (props.value > prev) {
                        return props.value;
                    }

                    if (prev < 100) {
                        return prev + 1;
                    } else {
                        clearInterval(intervalId as NodeJS.Timeout);
                        return prev;
                    }
                });
            }, 700);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [props.value]);

    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                marginTop: "128px",
                alignItems: "center",
            }}
        >
            <CircularProgress size={"9rem"} sx={{ color: "#7F56D9" }} variant="determinate" value={displayedValue} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontSize: 28,
                    }}
                >{`${Math.round(displayedValue)}%`}</Typography>
            </Box>
        </Box>
    );
};
