import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getAttitudes, getMatrixGenerationStatus, getRoles } from "../../api/requests/requests";
import { Attitude, CompetencesArea, MatrixGenerationStatus } from "../../api/types";
import { handleError } from "../../utils/handleError";

export const fetchRoles = createAsyncThunk("currentMatrix/fetchRoles", async (id: number) => {
    const response = await getRoles(id);
    return response;
});

export const fetchAttitudes = createAsyncThunk("currentMatrix/fetchAttitudes", async (id: number) => {
    const response = await getAttitudes(id);
    return response;
});

export const getGenerationStatus = createAsyncThunk(
    "currentMatrix/getMatrixGenerationStatus",
    async (args: { matrixId: number }, { dispatch }) => {
        try {
            const res = await getMatrixGenerationStatus(args.matrixId);
            return res;
        } catch (e) {
            handleError(e, dispatch);
            throw e;
        }
    },
);

export interface MatrixDataState {
    isGenerating: boolean;
    generation: MatrixGenerationStatus;
    roles: CompetencesArea[];
    areas: Attitude[];
    areasLoading: boolean;
    rolesLoading: boolean;
    id: number | null;
    name?: string;
    error?: string;
}

export const initialState: MatrixDataState = {
    isGenerating: false,
    generation: {
        completion_fraction: 0,
        generated_cards: 0,
        last_level: "",
        total_cards_for_level: 0,
    },
    areas: [],
    roles: [],
    areasLoading: false,
    rolesLoading: false,
    error: undefined,
    id: null,
};

const currentMatrixSlice = createSlice({
    name: "currentMatrix",
    initialState,
    reducers: {
        setMatrixId: (state, action: PayloadAction<number | null>) => {
            state.id = action.payload;
        },
        setMatrixName: (state, action: PayloadAction<string | undefined>) => {
            state.name = action.payload;
        },
        clearData: (state) => {
            state.areas = [];
            state.roles = [];
            state.id = null;
        },
        clearGenerationStatus: (state) => {
            state.generation = {
                completion_fraction: 0,
                generated_cards: 0,
                last_level: "",
                total_cards_for_level: 0,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.rolesLoading = true;
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.rolesLoading = false;
                state.roles = action.payload.areas;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.rolesLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAttitudes.pending, (state) => {
                state.areasLoading = true;
            })
            .addCase(fetchAttitudes.fulfilled, (state, action) => {
                state.areasLoading = false;
                state.areas = action.payload.attitudes;
            })
            .addCase(fetchAttitudes.rejected, (state, action) => {
                state.areasLoading = false;
                state.error = action.error.message;
            })
            .addCase(getGenerationStatus.pending, (state) => {
                state.isGenerating = true;
            })
            .addCase(getGenerationStatus.fulfilled, (state, action) => {
                state.isGenerating = false;
                state.generation = action.payload;
            })
            .addCase(getGenerationStatus.rejected, (state, action) => {
                state.isGenerating = false;
                state.error = action.error.message;
            });
    },
});

export const { setMatrixId, clearData, setMatrixName, clearGenerationStatus } = currentMatrixSlice.actions;
export default currentMatrixSlice.reducer;
