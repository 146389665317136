import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { buyPlan, getProfile } from "../../api/requests/requests";
import { Profile } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";

export const getUser = createAsyncThunk("user/getUser", async (_, { dispatch }) => {
    try {
        return await getProfile();
    } catch (e) {
        let errorMsg = "Wystąpił błąd. Spróbuj później.";
        if (isAxiosError(e) && e?.response?.data.detail) {
            errorMsg = e.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(e);
        throw e;
    }
});

export const buyPlanAsync = createAsyncThunk("user/buyPlan", async (planId: number, { dispatch }) => {
    try {
        const res = await buyPlan(planId);
        window.location.assign(res.url);
        return res;
    } catch (e) {
        let errorMsg = "Wystąpił błąd. Spróbuj później.";
        if (isAxiosError(e) && e?.response?.data.detail) {
            errorMsg = e.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(e);
        throw e;
    }
});

export interface UserState {
    user: Profile;
    loading: boolean;
    error?: string;
}

export const initialState: UserState = {
    user: {} as Profile,
    loading: false,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        clearUser: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(buyPlanAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(buyPlanAsync.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(buyPlanAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
