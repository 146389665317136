import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { createGoal, editGoal, getGoals } from "../../../api/requests/requests";
import { Goal, GoalData } from "../../../api/types";
import { showNotification } from "../../Notification/notificationSlice";

export const fetchGoals = createAsyncThunk("goals/getGoals", async (id: number, { dispatch }) => {
    try {
        return await getGoals(id);
    } catch (error) {
        let errorMsg = "Pobieranie celu zakończone niepowodzeniem.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

export const createSingleGoal = createAsyncThunk("goals/createGoal", async (goal: GoalData, { dispatch }) => {
    try {
        dispatch(
            showNotification({
                variant: "success",
                title: "Error!",
                subtitle: "Dodawanie celu zakończone powodzeniem.",
            }),
        );
        return await createGoal(goal);
    } catch (error) {
        let errorMsg = "Dodawanie celu zakończone niepowodzeniem.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

export const editSingleGoal = createAsyncThunk("goals/editGoal", async (goal: Goal, { dispatch }) => {
    try {
        dispatch(
            showNotification({
                variant: "success",
                title: "Error!",
                subtitle: "Edycja celu zakończona powodzeniem.",
            }),
        );
        return await editGoal(goal);
    } catch (error) {
        let errorMsg = "Edycja celu zakończone niepowodzeniem.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

export interface GoalState {
    goals: Goal[];
    loading: boolean;
    error?: string;
}

export const initialState: GoalState = {
    goals: [],
    loading: false,
    error: undefined,
};

const goalsSlice = createSlice({
    name: "goals",
    initialState,
    reducers: {
        clearData: (state) => {
            state.goals = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGoals.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGoals.fulfilled, (state, action) => {
                state.loading = false;
                state.goals = action.payload;
            })
            .addCase(fetchGoals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createSingleGoal.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSingleGoal.fulfilled, (state, action) => {
                state.loading = false;
                state.goals = [action.payload];
            })
            .addCase(createSingleGoal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(editSingleGoal.pending, (state) => {
                state.loading = true;
            })
            .addCase(editSingleGoal.fulfilled, (state, action) => {
                state.loading = false;
                state.goals = [action.payload];
            })
            .addCase(editSingleGoal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearData } = goalsSlice.actions;
export default goalsSlice.reducer;
