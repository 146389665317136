import { useSelector } from "react-redux";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Header } from "../../Tile/Header/Header";
import { RootState } from "../../../store/store";
import { Attitude } from "../../../api/types";

import { ReactComponent as Certificate } from "../../../assets/icons/16x16/certificate.svg";
import { ReactComponent as Plus } from "../../../assets/icons/16x16/plus.svg";

import { Action } from "../../Tile/Menu/Menu.types";

import { AddCompetenceGoalModal } from "../AddCompetenceGoalModal/AddCompetenceGoalModal";

import { TruncatedTextWithTooltip } from "../../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

import styles from "./Competence.module.scss";
import { CompetenceProps } from "./Competence.types";

const countVisibleWords = (goalsText: string): number => {
    const lines = goalsText.split("\n");
    let wordsCounter = 0;
    let linesCouter = 0;
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const wordsInLine = line.split(" ").filter((word) => word).length;
        linesCouter++;
        wordsCounter = wordsCounter + wordsInLine;
        if (wordsInLine > 14) {
            linesCouter++;
        }
        if (linesCouter > 2) {
            return wordsCounter;
        }
    }
    return wordsCounter;
};

export const Competence = ({ item }: CompetenceProps) => {
    const { t } = useTranslation();

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showAddCompetenceGoalModal, setShowAddCompetenceGoalModal] = useState<boolean>(false);

    const { areas: areasArray, roles } = useSelector((state: RootState) => state.currentMatrix);
    const { description, attitudes, area, background_color, border_color } = item;
    const { courses: coursesObject } = useSelector((state: RootState) => state.courses);

    const toogleMenuAction = () => {
        setShowMenu(!showMenu);
    };

    const handleAdd = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setShowAddCompetenceGoalModal(!showAddCompetenceGoalModal);
    };

    const getColor = () => {
        const colors = roles.find((role) => role.pk === area);
        if (!colors) {
            return {
                gradiend: `linear-gradient(${background_color}, #FFF 100%)`,
                color: border_color,
            };
        }
        return {
            gradiend: `linear-gradient(${colors?.background_color}, #FFF 100%)`,
            color: colors?.border_color,
        };
    };

    const matchedBadges = attitudes?.map((item) => {
        const matchingElement = areasArray?.find((element) => element.pk === item);
        return matchingElement ? { ...matchingElement } : null;
    }) as Attitude[];

    let courses: Array<{ link: string; desc: string }> = [];
    if (item?.pk && coursesObject[item.pk]) {
        courses = coursesObject[item.pk]?.map((item) => ({
            link: item.link,
            desc: item.title,
        }));
    }

    const menuItems: Action[] = [
        {
            name: t("kanbanPage.menu.createGoal"),
            action: handleAdd,
            icon: <Plus />,
            dataTestid: "createGoalButton",
        },
    ];

    const goalsText = item.goals?.map((goal) => goal.description).join("\n");
    const visibleWords = countVisibleWords(goalsText ?? "");

    return (
        <div className={styles.container} style={{ backgroundImage: getColor().gradiend }}>
            <div className={styles.containerInner}>
                <Header
                    competence={item}
                    areas={matchedBadges}
                    menuActions={menuItems}
                    isMenuOpen={showMenu}
                    toogleMenuAction={toogleMenuAction}
                />
                <p className={styles.description}>{description}</p>
                {/* <div
style={{
    width: "100%",
    height: "1px",
    marginBottom: "16px",
    marginTop: "16px",
    backgroundColor: getColor().color,
}}
/> */}
                <div className={styles.bottomContainer}>
                    <ul className={styles.coursesContainer}>
                        {courses.map((course, i) => {
                            return (
                                <li key={i} className={styles.courseWrapper}>
                                    <Certificate className={styles.courseIcon} />
                                    <a className={styles.courseLink} href={course.link}>
                                        {course.desc}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {goalsText && (
                    <div className={styles.goalsDescContainer}>
                        {`${t("kanbanPage.goals")}:`}
                        <TruncatedTextWithTooltip
                            visibleWords={visibleWords}
                            text={goalsText}
                            bgimage={getColor().gradiend}
                            color="#344054"
                            fontSize="14px"
                        />
                    </div>
                )}
            </div>
            <AddCompetenceGoalModal
                item={item}
                isOpen={showAddCompetenceGoalModal}
                toggleOpen={() => setShowAddCompetenceGoalModal(!showAddCompetenceGoalModal)}
            />
        </div>
    );
};
