import React, { FC } from "react";

import { useDispatch } from "react-redux";

import { Formik, Form, Field, FieldProps } from "formik";

import { InputLabel, TextField } from "@mui/material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import { AppDispatch } from "../../../store/store";

import { Button } from "../../Button/Button";

import { passwordReset } from "../../../store/LostAccount/lostAccountSlice";

import styles from "./StepOne.module.scss";

export const StepOne: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t("globals.validation.required")),
    });

    const handleSubmit = async (values: { email: string }, { resetForm }: { resetForm: () => void }) => {
        await dispatch(passwordReset(values.email));
        resetForm();
    };

    return (
        <div className={styles.formContainer}>
            <Formik initialValues={{ email: "" }} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {() => (
                    <Form className={styles.form}>
                        <p className={styles.title}>{t("loginPage.lostAccount")}</p>
                        <Field name="email" data-testid="emailInput">
                            {({ field, meta }: FieldProps) => (
                                <div>
                                    <InputLabel id="email-label" className={styles.label}>
                                        {t("loginPage.email")}
                                    </InputLabel>
                                    <TextField
                                        {...field}
                                        id="email-label"
                                        placeholder="email@email.com"
                                        error={meta.touched && Boolean(meta.error)}
                                        helperText={meta.touched && meta.error}
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>
                        <div className={styles.submitButtonContainer}>
                            <Button variant="secondary" size="medium" fullWidth type="submit">
                                {t("loginPage.resetPassword")}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
