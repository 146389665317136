import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useTranslation } from "react-i18next";

import Modal from "../Modal/Modal";

import { toggleAssignUserModal } from "../../store/Modal/modalSlice";
import { AppDispatch, RootState } from "../../store/store";

import { Button } from "../Button/Button";

import { ManagmentPanelSingleUserData, Matrix } from "../../api/types";

import { assignEmployee } from "../../store/EmployeesManagmentPanel/EmployeesManagmentPanel.slice";

import { fetchMatrixes } from "../../store/Matrixes/matrixesSlice";

import styles from "./AssignUserToModelModal.module.scss";

export const AssignUserToModelModal: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const { isOpen, user } = useSelector((state: RootState) => state.modal.assignUserModalOpen);
    const { matrixes } = useSelector((state: RootState) => state.matrixes);

    const [selectedModel, setSelectedModel] = useState<Matrix>();

    const handleEdit = () => {
        if (selectedModel?.id && selectedModel.name) {
            dispatch(
                assignEmployee({ matrixId: selectedModel.id, matrixName: selectedModel.name, userEmail: user.email }),
            );
            dispatch(toggleAssignUserModal({ isOpen: false, user: {} as ManagmentPanelSingleUserData }));
        }
    };

    const handleSelectChange = (e: SelectChangeEvent<number>) => {
        const selectedOption = matrixes.find((matrix) => matrix.id === e.target.value);
        setSelectedModel(selectedOption);
    };

    useEffect(() => {
        if (matrixes.length === 0) {
            dispatch(fetchMatrixes());
        }
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            actionButtons={{
                onClose: {
                    action: () =>
                        dispatch(toggleAssignUserModal({ isOpen: false, user: {} as ManagmentPanelSingleUserData })),
                },
                onSubmit: (
                    <Button variant="secondary" onClick={handleEdit}>
                        {t("managmentPanel.model.assign")}
                    </Button>
                ),
            }}
            title={t("managmentPanel.model.title")}
        >
            <div>
                <div className={styles.selectsContainer}>
                    <FormControl
                        sx={{
                            width: 300,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            id="model-select-label"
                            sx={{
                                "&.Mui-focused": {
                                    color: "#7F56D9",
                                },
                            }}
                        >
                            {t("managmentPanel.model.placeholder")}
                        </InputLabel>
                        <Select
                            labelId="model-select-label"
                            id="model-select"
                            value={selectedModel?.id}
                            onChange={handleSelectChange}
                            label={t("managmentPanel.model.placeholder")}
                        >
                            {matrixes.map((matrix) => (
                                <MenuItem key={matrix.id} value={matrix.id}>
                                    {matrix.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Modal>
    );
};
