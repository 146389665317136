export enum URLS {
    TILES = "/tiles/",
    REFRESH_TOKEN = "/auth/token/refresh/",
    PROFILE = "/auth/my_profile/",
    LANGUAGE = "/auth/change-language/",
    PASSWORD_RESET = "/auth/password_reset/",
    PASSWORD_RESET_CONFIRM = "/auth/reset/",
    LOGIN = "/auth/login/",
    REGISTER = "/auth/register/",
    COMPETENCES_CHECK = "/auth/competence/",
    MATRIX = "/matrix/",
    MATRIX_CONFIGURATION = "/matrix/configuration/",
    GENERATE = "/generate/",
    COMPETENCES = "/competence/",
    COMPETENCES_AREA = "/competence/area/",
    COMPETENCES_BADGES = "/competence/attitudes/",
    COMPETENCES_LEVEL = "/competence/level/",
    COMPETENCES_TABLE = "/competence/table/",
    COMPETENCES_EDIT = "/competence/competence/",
    SKILLS = "/skills/matrix/",
    SKILLS_USER_GOAL = "/skills/user_goal/",
    ORGANIZATION = "/organization/",
    ORGANIZATION_USERS = "/organization/my_organization_users/",
    ORGANIZATION_ADMIN_PANEL = "/organization/organization_admin_panel/",
    BUY_PLAN = `/payment/user_payment/create_checkout_session/`,
}

export enum WS_URLS {
    MATRIX = "ws/matrix/",
}
