import React, { FC } from "react";

import { CreateMatrixFromTemplate } from "../../components/CreateMatrixFromTemplate/CreateMatrixFromTemplate";

import styles from "./TemplatesPage.module.scss";

export const TemplatesPage: FC = () => {
    return (
        <div className={styles.wrapper}>
            <CreateMatrixFromTemplate />
        </div>
    );
};
