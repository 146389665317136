import React, { ButtonHTMLAttributes, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

import { HTML5Backend } from "react-dnd-html5-backend";

import { TouchBackend } from "react-dnd-touch-backend";

import { DndProvider } from "react-dnd";

import Slider from "react-slick";

import { DraggableItem } from "../DragableItem/DragableItem";

import { Competence } from "../../api/types";

import { AppDispatch, RootState } from "../../store/store";

import { moveSingleCompetence } from "../../store/Competences/competencesSlice";

import { MoveItemArgs } from "../DragableItem/DraggableItem.types";

import { Counter } from "../Counter/Counter";

import { ReactComponent as ChevronLeft } from "../../assets/icons/24x24/chevron-left.svg";
import { ReactComponent as Plus } from "../../assets/icons/24x24/plus.svg";

import { GeneratedLevelData, generateLevelsData } from "../../utils/mapper";

import { Button } from "../Button/Button";

import { AddTileModal } from "../Tile/AddTileModal/AddTileModal";

import { arabicToRoman } from "../../utils/arabicToRoman";

import { DraggableGridVerticalProps, DropLocation } from "./DraggableGridVertical.types";
import styles from "./DragableGridVertical.module.scss";

const Backend = isMobile ? TouchBackend : HTML5Backend;

const filterItems = (item: Competence, filters: { tiles: Array<number>; areas: Array<number> }) => {
    if (
        item.is_empty ||
        (item.area &&
            filters.tiles.includes(item.area) &&
            (item.attitudes?.some((itemBadge) => filters.areas.includes(itemBadge)) || item.attitudes?.length === 0))
    ) {
        return true;
    }
    return false;
};

export const DraggableGridVertical: React.FC<DraggableGridVerticalProps> = ({ filters }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { id, areas, roles } = useSelector((state: RootState) => state.currentMatrix);
    const { isEditing, levels, skipSublevels } = useSelector((state: RootState) => state.competences);

    const [editedTile, setEditedTile] = useState<Competence | undefined>(undefined);
    const [lastDropLocation, setLastDropLocation] = useState<DropLocation | null>(null);

    const moveItem = (args: MoveItemArgs) => {
        const {
            draggedId,
            draggedLevel,
            draggedRowIndex,
            draggedColumnIndex,
            droppedLevel,
            droppedRowIndex,
            droppedColumnIndex,
            droppedId,
            is_last_one,
        } = args;
        if (isEditing) {
            return;
        }
        setLastDropLocation((prevState) => {
            if (prevState?.droppedId !== droppedId) {
                // have to be here for now
            } else if (
                prevState &&
                prevState.droppedLevel === droppedLevel &&
                prevState.droppedRowIndex === droppedRowIndex &&
                prevState.droppedColumnIndex === droppedColumnIndex &&
                prevState.droppedId === droppedId
            ) {
                return prevState;
            }
            if (id) {
                if (droppedId) {
                    dispatch(
                        moveSingleCompetence({
                            matrixId: id,
                            id: draggedId,
                            id_taken_spot: droppedId,
                        }),
                    );
                } else {
                    dispatch(
                        moveSingleCompetence({
                            matrixId: id,
                            id: draggedId,
                            level: droppedLevel,
                            row_index: droppedRowIndex,
                            column_index: droppedColumnIndex,
                            is_last_one: is_last_one,
                        }),
                    );
                }
            }
            return {
                droppedId,
                droppedLevel,
                droppedRowIndex,
                droppedColumnIndex,
            };
        });
    };

    const levelsData = generateLevelsData(levels);

    const getColumnsContainerStyle = (level: GeneratedLevelData, index: number) => {
        const title = level.name.split(" ")[0];

        if (levelsData[index - 1]?.name.includes(title)) {
            if (levelsData[index + 1]?.name.includes(title)) {
                return "middleColumnTitleContainer";
            }
            return "lastColumnTitleContainer";
        }
        if (levelsData[index + 1]?.name.includes(title)) {
            return "firstColumnTitleContainer";
        }
        return "aloneColumnTitleContainer";
    };

    const getEmptyItemData = (level: GeneratedLevelData) => {
        return level.competences.find((competence) => competence.is_empty);
    };

    return (
        <>
            <DndProvider backend={Backend}>
                <div className={styles.columnsContainer}>
                    {levelsData.map((level, i) => {
                        const style = getColumnsContainerStyle(level, i) ?? "";
                        const emptyItemData = getEmptyItemData(level);
                        return (
                            <div key={i} className={styles.row}>
                                <div className={styles[style]}>
                                    {(style === "middleColumnTitleContainer" ||
                                        style === "aloneColumnTitleContainer") && (
                                        <div className={styles.titleWrapper}>
                                            <p className={styles.levelName}>
                                                {level.name.split(" ")[0].replace(/_/g, " ")}
                                            </p>
                                            <Counter
                                                counter={level.count_user_competence}
                                                max={level.count_all_competence}
                                            />
                                        </div>
                                    )}
                                </div>
                                {!skipSublevels && (
                                    <div className={styles.levelNameWrapper}>
                                        <p className={styles.levelName}>{arabicToRoman(level.name)}</p>
                                        <Button
                                            icon={<Plus />}
                                            variant="icon"
                                            size="large"
                                            onClick={() => setEditedTile(emptyItemData)}
                                        />
                                    </div>
                                )}

                                <div className={styles.itemsContainer}>
                                    {level.competences
                                        .filter((item) => filterItems(item, filters))
                                        .map((item, columnIndex) => {
                                            const isLastOne = level.competences.length === columnIndex + 1;
                                            return (
                                                <DraggableItem
                                                    key={
                                                        item?.pk ||
                                                        `${item.level}-${item.row_index}-${item.column_index}`
                                                    }
                                                    item={item}
                                                    is_last_one={isLastOne}
                                                    onDrop={moveItem}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </DndProvider>

            {editedTile && id && (
                <AddTileModal
                    areas={areas}
                    roles={roles}
                    item={editedTile}
                    isOpen={!!editedTile}
                    matrixId={id}
                    is_last_one={true}
                    toggleOpen={() => setEditedTile(undefined)}
                />
            )}
        </>
    );
};
