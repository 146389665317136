import React, { FC } from "react";

import styles from "./PageHeader.module.scss";
import { PageHeaderProps } from "./PageHeader.types";

export const PageHeader: FC<PageHeaderProps> = ({ title, subTitle, badge }) => {
    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>
                {title} {badge && <span className={styles.badge}>{badge}</span>}
            </p>
            {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        </div>
    );
};
