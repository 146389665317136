import React, { FC } from "react";

import { Icon } from "../Icon/Icon";

import { IconSize } from "../Icon/Icon.types";

import { TruncatedTextWithTooltip } from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

import styles from "./SectionHeader.module.scss";
import { SectionHeaderProps } from "./SectionHeader.types";

export const SectionHeader: FC<SectionHeaderProps> = ({
    title,
    subTitle,
    icon,
    titleSize = "medium",
    variant = "primary",
    isTruncatedSubtitle,
}) => {
    return (
        <div className={styles.wrapper}>
            {icon && (
                <div className={styles.iconContainer}>
                    <Icon size={IconSize["large"]}>{icon}</Icon>
                </div>
            )}
            {variant === "primary" ? (
                <div>
                    <p className={titleSize === "medium" ? styles.titleMedium : styles.titleHuge}>{title}</p>
                    {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
                </div>
            ) : (
                <div>
                    <p className={titleSize === "medium" ? styles.titleSecondaryMedium : styles.titleSecondaryHuge}>
                        {title}
                    </p>
                    <p className={styles.subTitleSecondary}>
                        {subTitle && isTruncatedSubtitle ? (
                            <TruncatedTextWithTooltip visibleWords={5} text={subTitle} />
                        ) : (
                            subTitle
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};
