import React from "react";

import { ReactComponent as Dots } from "../../../../assets/icons/24x24/dots-vertical.svg";

import styles from "./Header.module.scss";
import { HeaderProps } from "./Header.types";

export const Header = ({ title, counter }: HeaderProps) => {
    return (
        <div className={styles.wrapper}>
            <p className={styles.wrapperLeft}>
                <span className={styles.title}>{title}</span>
                <span className={styles.counter}>{counter}</span>
            </p>
            <div className={styles.wrapperRight}>{/* <Dots /> */}</div>
        </div>
    );
};
