import React from "react";
import { useDrag, useDrop } from "react-dnd";

import Tile from "../Tile/Tile";

import { DraggableItemProps } from "./DraggableItem.types";

export const DraggableItem: React.FC<DraggableItemProps> = ({ item, onDrop, is_last_one }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        type: "ITEM",
        item: { id: item.pk, rowIndex: item.row_index, columnIndex: item.column_index, level: item.level },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [{ isDropping }, drop] = useDrop({
        accept: "ITEM",
        drop: (draggedItem: { id: number; rowIndex: number; columnIndex: number; level: number }) => {
            if (
                draggedItem.rowIndex !== item.row_index ||
                draggedItem.columnIndex !== item.column_index ||
                draggedItem.level !== item.level
            ) {
                if (draggedItem.id) {
                    onDrop({
                        draggedId: draggedItem.id,
                        draggedLevel: draggedItem.level,
                        draggedRowIndex: draggedItem.rowIndex,
                        draggedColumnIndex: draggedItem.columnIndex,
                        droppedLevel: item.level,
                        droppedRowIndex: item.row_index,
                        droppedColumnIndex: item.column_index,
                        droppedId: item.pk,
                        is_last_one: is_last_one,
                    });
                }
            }
        },
        collect: (monitor) => ({
            isDropping: monitor.isOver(),
        }),
    });
    return (
        <div key={item.pk} ref={(node) => drag(drop(preview(node)))}>
            <Tile item={item} is_last_one={is_last_one} />
        </div>
    );
};
