import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Card,
    CardContent,
    CircularProgress,
    TextField,
    IconButton,
    Tooltip,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";

import { Formik, Form, Field, FieldProps, FormikHelpers, FieldArray } from "formik";

import * as Yup from "yup";

import { PlaylistRemove } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../store/store";

import { Button } from "../Button/Button";

import {
    clearMatrix,
    createMatrix,
    geConfigurationJobLevels,
    generateMatrix,
    setStep,
} from "../../store/AiMatrix/createMatrixSlice";

import { clearAreas, createArea, deleteArea, editArea, setAreasLoading } from "../../store/AiMatrix/aiAreasSlice";

import { clearBadges, createBadge, deleteBadge, editBadge, setBadgesLoading } from "../../store/AiMatrix/aiBadgesSlice";

import { Attitude, CompetencesArea } from "../../api/types";

import { setMatrixId, setMatrixName } from "../../store/CurrentMatrix/currentMatrixSlice";

import { ReactComponent as Trash } from "../../assets/icons/16x16/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/16x16/edit.svg";
import { ReactComponent as Help } from "../../assets/icons/16x16/help.svg";
import { ReactComponent as Plus } from "../../assets/icons/16x16/plus.svg";
import { ReactComponent as Cloud } from "../../assets/icons/20x20/upload-cloud.svg";

import { StepLine } from "../StepLine/StepLine";

import styles from "./CreateMatrix.module.scss";

import { FormValues } from "./CreateMatrix.types";

import { AddItemForm } from "./AddItemForm/AddItemForm";
import { Areas } from "./Areas/Areas";
import { Badges } from "./Badges/Badges";

const initialValues = { name: "", description: "", links: [""], files: [{}] as File[], lang: "", jobsLevel: 999 };

export const CreateMatrix: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [language] = React.useState(i18n.language);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t("globals.validation.required")),
        description: Yup.string().required(t("globals.validation.required")),
        links: Yup.array().of(Yup.string().matches(/^[^\s]*\.[^\s.]+$/, t("globals.validation.urlInvalid"))),
    });

    const [editedCompetence, setEditedCompetence] = useState<null | CompetencesArea>(null);
    const [editedBadge, setEditedBadge] = useState<null | Attitude>(null);

    const {
        data,
        loading: generatingMatrix,
        step,
        jobLevels,
        levelsLoading,
    } = useSelector((state: RootState) => state.aiMatrix);
    const { loading: loadingAreas, areas } = useSelector((state: RootState) => state.aiAreas);
    const { loading: loadingBadges, badges } = useSelector((state: RootState) => state.aiBadges);

    const isAreasInitStep = !(areas.length > 0) && data.id && step === 1;
    const isBadgesInitStep = !(badges.length > 0) && step === 2;
    const isGeneratingMatrix = generatingMatrix && areas.length > 0 && badges.length > 0;

    useEffect(() => {
        dispatch(geConfigurationJobLevels());
    }, []);

    const onSubmit = async (formValues: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        if (!formValues.name) return;
        const res = await dispatch(createMatrix(formValues));
        if (res.type === "createMatrix/addMatrix/fulfilled") {
            dispatch(setStep(1));
            resetForm();
        }
    };

    const handleGenerateCompetences = async () => {
        const res = await dispatch(generateMatrix(data.id));
        if (res.type === "createMatrix/generateMatrix/fulfilled") {
            dispatch(setAreasLoading(false));
            dispatch(setBadgesLoading(false));
            dispatch(clearBadges());
            dispatch(clearAreas());
            dispatch(clearMatrix());
            dispatch(setMatrixId(data.id));
            dispatch(setMatrixName(data.name));
            navigate("/matrixes");
        }
    };

    const handleEdit = (area: CompetencesArea) => {
        setEditedCompetence(area);
    };

    const handleBadgeEdit = (badge: Attitude) => {
        setEditedBadge(badge);
    };

    const handleAreaDelete = (area: CompetencesArea) => {
        dispatch(deleteArea({ area: area, id: data.id }));
    };

    const handleBadgeDelete = (badge: Attitude) => {
        dispatch(deleteBadge({ badge: badge, id: data.id }));
    };

    const handleEditConfirm = async () => {
        if (!editedCompetence) return;
        await dispatch(editArea({ area: editedCompetence, id: data.id }));
        setEditedCompetence(null);
    };

    const handleBadgeEditConfirm = async () => {
        if (!editedBadge) return;
        await dispatch(editBadge({ badge: editedBadge, id: data.id }));
        setEditedBadge(null);
    };

    const handleBadgeAdd = (newBadgeName: string) => {
        dispatch(createBadge({ badgeName: newBadgeName, id: data.id }));
    };

    const handleAreaAdd = (newAreaName: string) => {
        dispatch(createArea({ areaName: newAreaName, id: data.id }));
    };

    const handleGoFirstStep = () => {
        dispatch(setStep(1));
    };

    const handleGoSecondStep = () => {
        dispatch(setStep(2));
    };

    if (isGeneratingMatrix) {
        return <CircularProgress />;
    }

    if (isAreasInitStep) {
        return (
            <div className={styles.wrapper}>
                <Areas />
            </div>
        );
    }

    if (isBadgesInitStep) {
        return (
            <div className={styles.wrapper}>
                <Badges />
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            {!(areas.length > 0) && step === 0 && (
                <>
                    <StepLine current={0} size={3} />
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({ setFieldValue }) => (
                            <Form className={styles.form}>
                                <p className={styles.title}>{t("createModel.firstStep.title")}</p>
                                <p className={styles.subTitle}>{t("createModel.firstStep.subTitle")}</p>
                                <Field name="name" data-testid="nameInput">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <div className={styles.labelTooltipContainer}>
                                                <InputLabel htmlFor="name-label" className={styles.label}>
                                                    {t("createModel.firstStep.model.name.label")}
                                                    <span className={styles.labelRequired}>*</span>
                                                </InputLabel>
                                                <Tooltip
                                                    className={styles.tooltip}
                                                    title={t("createModel.firstStep.model.name.tooltip")}
                                                    placement="right-end"
                                                    slotProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: "14px",
                                                                backgroundColor: "#9E77ED",
                                                                color: "white",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Help className={styles.inputIcon} />
                                                </Tooltip>
                                            </div>
                                            <TextField
                                                {...field}
                                                id="name-label"
                                                placeholder={t("createModel.firstStep.model.name.placeholder")}
                                                required
                                                error={meta.touched && Boolean(meta.error)}
                                                helperText={meta.touched && meta.error}
                                                className={styles.textField}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        padding: "10px 14px",
                                                        height: "24px",
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#7F56D9",
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </Field>

                                <Field name="description">
                                    {({ field, meta }: FieldProps) => (
                                        <div>
                                            <div className={styles.labelTooltipContainer}>
                                                <InputLabel htmlFor="desc-label" className={styles.label}>
                                                    {t("createModel.firstStep.model.description.label")}
                                                    <span className={styles.labelRequired}>*</span>
                                                </InputLabel>
                                                <Tooltip
                                                    className={styles.tooltip}
                                                    title={t("createModel.firstStep.model.description.tooltip")}
                                                    placement="right-end"
                                                    slotProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: "14px",
                                                                backgroundColor: "#9E77ED",
                                                                color: "white",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Help className={styles.inputIcon} />
                                                </Tooltip>
                                            </div>
                                            <TextField
                                                {...field}
                                                id="desc-label"
                                                placeholder={t("createModel.firstStep.model.description.placeholder")}
                                                multiline
                                                required
                                                error={meta.touched && Boolean(meta.error)}
                                                helperText={meta.touched && meta.error}
                                                className={styles.textField}
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: "#7F56D9",
                                                        },
                                                        padding: "10px 14px",
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                </Field>

                                <Field name="jobsLevel">
                                    {({ field, meta }: FieldProps<string>) => (
                                        <div>
                                            <div className={styles.labelTooltipContainer}>
                                                <InputLabel htmlFor="jobsLevel-label" className={styles.label}>
                                                    {t("createModel.firstStep.model.jobsLevel.label")}
                                                    <span className={styles.labelRequired}>*</span>
                                                </InputLabel>
                                                <Tooltip
                                                    className={styles.tooltip}
                                                    title={t("createModel.firstStep.model.jobsLevel.tooltip")}
                                                    placement="right-end"
                                                    slotProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: "14px",
                                                                backgroundColor: "#9E77ED",
                                                                color: "white",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Help className={styles.inputIcon} />
                                                </Tooltip>
                                            </div>
                                            <Select
                                                {...field}
                                                id={`jobsLevel-select`}
                                                labelId={`jobsLevel-label`}
                                                style={{ maxHeight: "40px" }}
                                                required
                                                onChange={(e: SelectChangeEvent) => {
                                                    field.onChange(e);
                                                }}
                                                error={meta.touched && Boolean(meta.error)}
                                                sx={{
                                                    width: "100%",
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                }}
                                            >
                                                {levelsLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    jobLevels.map((level, i) => {
                                                        return (
                                                            <MenuItem key={i} value={level.id}>
                                                                {level.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                )}
                                            </Select>
                                        </div>
                                    )}
                                </Field>

                                <Field name="lang">
                                    {({ field, meta }: FieldProps<string>) => (
                                        <div>
                                            <div className={styles.labelTooltipContainer}>
                                                <InputLabel htmlFor="lang-label" className={styles.label}>
                                                    {t("createModel.firstStep.model.language.label")}
                                                    <span className={styles.labelRequired}>*</span>
                                                </InputLabel>
                                                <Tooltip
                                                    className={styles.tooltip}
                                                    title={t("createModel.firstStep.model.language.tooltip")}
                                                    placement="right-end"
                                                    slotProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: "14px",
                                                                backgroundColor: "#9E77ED",
                                                                color: "white",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Help className={styles.inputIcon} />
                                                </Tooltip>
                                            </div>
                                            <Select
                                                {...field}
                                                id={`lang-select`}
                                                labelId={`lang-label`}
                                                style={{ maxHeight: "40px" }}
                                                defaultValue={language ?? "pl"}
                                                required
                                                onChange={(e: SelectChangeEvent) => {
                                                    field.onChange(e);
                                                }}
                                                error={meta.touched && Boolean(meta.error)}
                                                sx={{
                                                    width: "100%",
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                }}
                                            >
                                                <MenuItem value="pl">PL</MenuItem>
                                                <MenuItem value="en">EN</MenuItem>
                                            </Select>
                                        </div>
                                    )}
                                </Field>

                                <FieldArray name="links">
                                    {(arrayHelpers) => {
                                        const { form, remove, push } = arrayHelpers;
                                        return (
                                            <div>
                                                <div className={styles.labelTooltipContainer}>
                                                    <InputLabel htmlFor="link-label" className={styles.label}>
                                                        {t("createModel.firstStep.model.link.label")}
                                                    </InputLabel>
                                                    <Tooltip
                                                        title={t("createModel.firstStep.model.link.tooltip")}
                                                        className={styles.tooltip}
                                                        placement="right-end"
                                                        slotProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    backgroundColor: "#9E77ED",
                                                                    color: "white",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <Help className={styles.inputIcon} />
                                                    </Tooltip>
                                                </div>
                                                <div className={styles.linksContainer}>
                                                    {form.values.links.map((link: string, i: number) => {
                                                        return (
                                                            <div key={i} className={styles.linkContainer}>
                                                                <Field
                                                                    as={TextField}
                                                                    id="link-label"
                                                                    placeholder={t(
                                                                        "createModel.firstStep.model.link.placeholder",
                                                                    )}
                                                                    name={`links.${i}`}
                                                                    className={styles.textField}
                                                                    error={
                                                                        form.errors?.links &&
                                                                        //@ts-ignore
                                                                        Boolean(form.errors?.links[i])
                                                                    }
                                                                    helperText={
                                                                        //@ts-ignore
                                                                        form.errors?.links && form.errors?.links[i]
                                                                    }
                                                                    sx={{
                                                                        "& .MuiInputBase-input": {
                                                                            padding: "10px 14px",
                                                                            height: "24px",
                                                                        },
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#7F56D9",
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                                <div
                                                                    className={
                                                                        form.values.links.length > 1
                                                                            ? styles.countButtonsContainer
                                                                            : styles.countButtonContainer
                                                                    }
                                                                >
                                                                    {form.values.links.length > 1 && (
                                                                        <Button
                                                                            icon={<PlaylistRemove />}
                                                                            onClick={() => remove(i)}
                                                                            type="button"
                                                                            variant="helper"
                                                                            size="small"
                                                                        >
                                                                            {t("createModel.delete")}
                                                                        </Button>
                                                                    )}
                                                                    {form.values.links.length - 1 === i && (
                                                                        <Button
                                                                            icon={<Plus />}
                                                                            onClick={() => push("")}
                                                                            type="button"
                                                                            variant="helper"
                                                                            size="small"
                                                                        >
                                                                            {t("createModel.firstStep.addLink")}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                                <FieldArray name="files">
                                    {(fieldArrayProps) => {
                                        const { push, remove, form } = fieldArrayProps;
                                        const { files } = form.values;
                                        return (
                                            <div>
                                                <div className={styles.labelTooltipContainer}>
                                                    <InputLabel htmlFor="attachment-label" className={styles.label}>
                                                        {t("createModel.firstStep.model.attachment.label")}
                                                    </InputLabel>
                                                    <Tooltip
                                                        title={t("createModel.firstStep.model.attachment.tooltip")}
                                                        className={styles.tooltip}
                                                        placement="right-end"
                                                        slotProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    backgroundColor: "#9E77ED",
                                                                    color: "white",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <Help className={styles.inputIcon} />
                                                    </Tooltip>
                                                </div>
                                                <div className={styles.fileSizeNoteContainer}>
                                                    <InputLabel 
                                                        sx={{
                                                            maxWidth: '100%',
                                                            whiteSpace: 'normal',
                                                            overflow: 'visible',
                                                        }} 
                                                        htmlFor="attachment-file-size-note" 
                                                        className={styles.fileSizeNote}
                                                    >
                                                        {t("createModel.firstStep.model.attachment.fileSizeNote")}
                                                    </InputLabel>
                                                </div>
                                                <div className={styles.uploadsContainer}>
                                                    {files.map((file: File, i: number) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className={styles.uploadContainer}
                                                                onDragOver={(event) => {
                                                                    event.preventDefault();
                                                                }}
                                                                onDrop={(event) => {
                                                                    event.preventDefault();
                                                                    const file = event.dataTransfer.files[0];
                                                                    setFieldValue(`files[${i}]`, file);
                                                                }}
                                                            >
                                                                <input
                                                                    type="file"
                                                                    id={`document-${i}`}
                                                                    name={`document-${i}`}
                                                                    data-testid={`documentInput-${i}`}
                                                                    onChange={(
                                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                                    ) => {
                                                                        const file = event.currentTarget.files?.[0];
                                                                        setFieldValue(`files[${i}]`, file);
                                                                    }}
                                                                    className={styles.fileInput}
                                                                />
                                                                <label
                                                                    htmlFor={`document-${i}`}
                                                                    className={styles.uploadButton}
                                                                >
                                                                    <IconButton component="span">
                                                                        <Cloud className={styles.uploadIcon} />
                                                                    </IconButton>
                                                                    <span className={styles.wholeSentence}>
                                                                        {file?.name || (
                                                                            <>
                                                                                <span className={styles.firstSentence}>
                                                                                    {t(
                                                                                        "createModel.firstStep.model.attachment.placeholder.firstSentence",
                                                                                    )}{" "}
                                                                                </span>
                                                                                <span>
                                                                                    {" "}
                                                                                    {t(
                                                                                        "createModel.firstStep.model.attachment.placeholder.secondSentence",
                                                                                    )}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </label>

                                                                <div
                                                                    className={
                                                                        form.values.files.length > 1
                                                                            ? styles.countButtonsContainer
                                                                            : styles.countButtonContainer
                                                                    }
                                                                >
                                                                    {form.values.files.length > 1 && (
                                                                        <Button
                                                                            icon={<PlaylistRemove />}
                                                                            onClick={() => remove(i)}
                                                                            type="button"
                                                                            variant="helper"
                                                                            size="small"
                                                                        >
                                                                            {t("createModel.delete")}
                                                                        </Button>
                                                                    )}
                                                                    {form.values.files.length - 1 === i && (
                                                                        <Button
                                                                            icon={<Plus />}
                                                                            onClick={() => push("")}
                                                                            type="button"
                                                                            variant="helper"
                                                                            size="small"
                                                                        >
                                                                            {t("createModel.firstStep.addAttachment")}
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                                <div className={styles.createButtonContainer}>
                                    <Button variant="secondary" type="submit" size="medium">
                                        {t("createModel.next")}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            {loadingAreas ? (
                <CircularProgress />
            ) : (
                <div>
                    {areas.length > 0 && step === 1 && (
                        <>
                            <StepLine current={1} size={3} />
                            <p className={styles.title}>{t("createModel.secondStep.title")}</p>
                            <p className={styles.subTitle}>{t("createModel.secondStep.subTitle")}</p>
                            <p className={styles.stepSubTitle}>{t("createModel.secondStep.areas")}</p>
                            {areas.map((area) => {
                                return area.pk === editedCompetence?.pk ? (
                                    <div key={area.pk} className={styles.editedContainer}>
                                        <TextField
                                            label={t("createModel.editable")}
                                            value={editedCompetence.name}
                                            className={styles.editableTextField}
                                            onChange={(e) =>
                                                setEditedCompetence({ ...editedCompetence, name: e.target.value })
                                            }
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                        />
                                        <div className={styles.actionContainer}>
                                            <Button
                                                onClick={() => setEditedCompetence(null)}
                                                type="button"
                                                variant="helper"
                                                size="small"
                                            >
                                                {t("createModel.cancel")}
                                            </Button>

                                            <Button
                                                onClick={handleEditConfirm}
                                                type="button"
                                                variant="helper"
                                                size="small"
                                            >
                                                {t("createModel.save")}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Card key={area.pk} style={{ marginBottom: "10px" }}>
                                        <CardContent className={styles.cardContent}>
                                            <span>{area.name}</span>
                                            <div className={styles.actionButtonsContainer}>
                                                <button className={styles.iconButton} onClick={() => handleEdit(area)}>
                                                    <Edit />
                                                </button>
                                                <button
                                                    className={styles.iconDeleteButton}
                                                    onClick={() => handleAreaDelete(area)}
                                                >
                                                    <Trash />
                                                </button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                            {areas.length < 6 && (
                                <AddItemForm
                                    title={t("createModel.secondStep.newItem")}
                                    handleBadgeAdd={handleAreaAdd}
                                />
                            )}

                            <div className={styles.firstStepButtonContainer}>
                                <Button variant="secondary" type="button" onClick={handleGoSecondStep}>
                                    {t("createModel.next")}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
            {loadingBadges ? (
                <CircularProgress />
            ) : (
                <div>
                    {badges.length > 0 && step === 2 && (
                        <>
                            <StepLine current={2} size={3} />
                            <p className={styles.title}>{t("createModel.thirdStep.title")}</p>
                            <p className={styles.subTitle}>{t("createModel.thirdStep.subTitle")}</p>
                            <p className={styles.stepSubTitle}>{`${t("createModel.thirdStep.attitudes")}:`}</p>
                            {badges.map((badge) => {
                                return badge.pk === editedBadge?.pk ? (
                                    <div key={badge.pk} className={styles.editedContainer}>
                                        <TextField
                                            label={t("createModel.editable")}
                                            value={editedBadge.name}
                                            className={styles.editableTextField}
                                            onChange={(e) => setEditedBadge({ ...editedBadge, name: e.target.value })}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: "#7F56D9",
                                                },
                                            }}
                                        />
                                        <div className={styles.actionContainer}>
                                            <Button
                                                onClick={() => setEditedBadge(null)}
                                                type="button"
                                                variant="helper"
                                                size="small"
                                            >
                                                {t("createModel.delete")}
                                            </Button>
                                            <Button
                                                onClick={handleBadgeEditConfirm}
                                                type="button"
                                                variant="helper"
                                                size="small"
                                            >
                                                {t("createModel.save")}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Card key={badge.pk} style={{ marginBottom: "10px" }}>
                                        <CardContent className={styles.cardContent}>
                                            <span>{badge.name}</span>
                                            <div className={styles.actionButtonsContainer}>
                                                <button
                                                    className={styles.iconButton}
                                                    onClick={() => handleBadgeEdit(badge)}
                                                >
                                                    <Edit />
                                                </button>
                                                <button
                                                    className={styles.iconDeleteButton}
                                                    onClick={() => handleBadgeDelete(badge)}
                                                >
                                                    <Trash />
                                                </button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                            {badges.length < 6 && (
                                <AddItemForm
                                    title={t("createModel.thirdStep.newItem")}
                                    handleBadgeAdd={handleBadgeAdd}
                                />
                            )}
                        </>
                    )}
                </div>
            )}

            {step === 2 && (
                <div className={styles.thirdStepButtonsContainer}>
                    <Button variant="primary" type="button" onClick={handleGoFirstStep}>
                        {t("createModel.back")}
                    </Button>
                    <Button variant="secondary" type="button" onClick={handleGenerateCompetences}>
                        {t("createModel.generate")}
                    </Button>
                </div>
            )}
        </div>
    );
};
