import React from "react";
import { Box, PaginationItem, Pagination as PaginationMui } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Button } from "../Button/Button";
import { ReactComponent as Left } from "../../assets/icons/16x16/arrow-left.svg";
import { ReactComponent as Right } from "../../assets/icons/16x16/arrow-right.svg";

import { PaginationProps } from "./Pagination.types";
import styles from "./Pagination.module.scss";

export const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, isPrev, isNext, onPageChange }) => {
    const { t } = useTranslation();

    return (
        <Box className={styles.paginationContainer}>
            <Button
                icon={<Left />}
                onClick={(e) => onPageChange(e, currentPage - 1)}
                disabled={!isPrev}
                style={{ fontWeight: 600, fontSize: "14px" }}
            >
                {t("managmentPanel.previous")}
            </Button>
            <PaginationMui
                count={totalPages}
                page={currentPage}
                onChange={onPageChange}
                hideNextButton
                hidePrevButton
                color="standard"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "16px",
                    ".Mui-selected": { backgroundColor: "#F9FAFB", width: "40px", height: "40px", borderRadius: "8px" },
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{ previous: "button", next: "button" }}
                        sx={{
                            "&.MuiPaginationItem-root": {
                                width: "40px",
                                height: "40px",
                                color: "#182230",
                                fontWeight: 500,
                            },
                            "&:hover": {
                                backgroundColor: "#F9FAFB",
                                width: "40px",
                                height: "40px",
                                borderRadius: "8px",
                                color: "#182230",
                                fontWeight: 500,
                            },
                            "&.MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: "#F9FAFB",
                            },
                        }}
                        {...item}
                        onClick={(event) => {
                            if (item.type === "previous" && currentPage > 1) {
                                onPageChange(event, currentPage - 1);
                            } else if (item.type === "next" && currentPage < totalPages) {
                                onPageChange(event, currentPage - 1);
                            } else if (item.type === "page") {
                                onPageChange(event, item.page as number);
                            }
                        }}
                    />
                )}
            />
            <Button
                icon={<Right />}
                iconPosition="right"
                onClick={(e) => onPageChange(e, currentPage + 1)}
                disabled={!isNext}
                style={{ fontWeight: 600, fontSize: "14px" }}
            >
                {t("managmentPanel.next")}
            </Button>
        </Box>
    );
};
