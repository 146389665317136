// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_container__xI3u9 {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  height: 244px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/Card/Card.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,WAAA;AADJ","sourcesContent":["@use '../../../styles/colors.scss' as colors;\r\n\r\n.container {\r\n    display: flex;\r\n    text-align: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    border-radius: 8px;\r\n    box-sizing: border-box;\r\n    height: 244px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Card_container__xI3u9`
};
export default ___CSS_LOADER_EXPORT___;
