import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getMatrixes } from "../../api/requests/requests";
import { Matrix } from "../../api/types";

export interface MatrixesState {
    loading: boolean;
    matrixes: Matrix[];
    error?: string;
}

export const initialState: MatrixesState = {
    matrixes: [],
    loading: false,
    error: undefined,
};

export const fetchMatrixes = createAsyncThunk("matrixes/fetchMatrixes", async () => {
    const response = await getMatrixes();
    return response;
});

const matrixesSlice = createSlice({
    name: "matrixes",
    initialState,
    reducers: {
        clearMatrixes: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMatrixes.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchMatrixes.fulfilled, (state, action) => {
                state.loading = false;
                state.matrixes = action.payload;
            })
            .addCase(fetchMatrixes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearMatrixes } = matrixesSlice.actions;
export default matrixesSlice.reducer;
