import React, { useState } from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Logout } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { Tooltip } from "@mui/material";

import Logo from "../../assets/pngs/logo.png";
import { ReactComponent as Wallet } from "../../assets/icons/20x20/wallet.svg";
import { ReactComponent as Help } from "../../assets/icons/16x16/help.svg";
import { Button } from "../Button/Button";

import { selectAccessToken } from "../../store/Auth_User/authSlice";
import { useLogout } from "../../utils/useLogout";
import { AppDispatch, RootState } from "../../store/store";

import { EmployeeList } from "../EmployeeList/EmployeeList";

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

import styles from "./Navbar.module.scss";
import { NavbarProps } from "./Navbar.types";

export const Navbar: React.FC<NavbarProps> = ({ actionButtons, employeeDatas, showPayment }) => {
    const accessToken = useSelector(selectAccessToken);
    const { user } = useSelector((state: RootState) => state.userStore);
    const dispatch = useDispatch<AppDispatch>();
    const logout = useLogout();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, action: () => void) => {
        action();
        setAnchorEl(event.currentTarget);
    };

    const handleToggle = (action: () => void) => {
        action();
        setAnchorEl(undefined);
    };

    const handleLogout = () => {
        dispatch(logout);
    };

    const handlePayment = () => {
        navigate("/payment");
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                    <RouterLink to={"/"} className={styles.routerLinkLogo}>
                        <img src={Logo} className={styles.logo} alt="outcomesskills" />
                    </RouterLink>
                    {actionButtons && (
                        <div className={styles.actionButtonsContainer}>
                            {actionButtons.map((button, i) => {
                                return (
                                    <div key={i} className={styles.actionButtonContainer}>
                                        <Button
                                            onClick={(e) =>
                                                handleClick(
                                                    e as React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    button.action,
                                                )
                                            }
                                        >
                                            {button.text}
                                        </Button>
                                        {button.tooltip && (
                                            <Tooltip
                                                title={button.tooltip}
                                                slotProps={{
                                                    tooltip: {
                                                        sx: {
                                                            fontSize: "14px",
                                                            backgroundColor: "#9E77ED",
                                                            color: "white",
                                                            paddingTop: "50px",
                                                        },
                                                    },
                                                }}
                                            >
                                                <Help className={styles.inputIcon} />
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            })}
                            {employeeDatas?.isOpen && anchorEl && (
                                <EmployeeList
                                    toggleAction={() => handleToggle(employeeDatas.action)}
                                    anchorEl={anchorEl}
                                    isOpen={employeeDatas.isOpen}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.rightWrapper}>
                    {/* <div className={styles.avatar}>A</div> */}
                    <LanguageSwitcher />
                    {showPayment && (
                        <Button
                            iconPosition="left"
                            icon={<Wallet style={{ color: user.is_paid ? "green" : "red" }} />}
                            onClick={handlePayment}
                        >
                            {t("globals.subscription")}
                        </Button>
                    )}
                    {accessToken ? (
                        <Button iconPosition="left" icon={<Logout />} onClick={handleLogout}>
                            {t("globals.logout")}
                        </Button>
                    ) : (
                        <RouterLink to="/login">
                            <Button data-testid="loginButton">{t("globals.login")}</Button>
                        </RouterLink>
                    )}
                </div>
            </div>
        </div>
    );
};
