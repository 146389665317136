import React, { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { Button } from "../../Button/Button";

import Modal from "../../Modal/Modal";

import { AppDispatch } from "../../../store/store";

import { showNotification } from "../../../store/Notification/notificationSlice";

import { createGoal } from "../../../store/Kanban/Columns/Columns.slice";

import styles from "./AddGoalModal.module.scss";
import { AddGoalModalProps } from "./AddGoalModal.types";

export const AddGoalModal: FC<AddGoalModalProps> = ({ isOpen, toggleOpen, column }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const maxLength = 100;
    const [charactersLeft, setCharactersLeft] = useState<number>(maxLength);
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        setCharactersLeft(maxLength - description.length);
    }, [description]);

    const handleAddNewTile = () => {
        if (!description) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Sukces!",
                    subtitle: "Opis jest wymagany.",
                }),
            );
            return;
        }
        dispatch(createGoal({ description: description, order: column.order }));
        toggleOpen();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length <= maxLength) {
            setDescription(newValue);
            setCharactersLeft(maxLength - newValue.length);
        }
    };

    return (
        <Modal
            actionButtons={{
                onClose: {
                    action: () => {
                        toggleOpen();
                        setDescription("");
                    },
                },
                onSubmit: (
                    <Button onClick={handleAddNewTile} variant="secondary">
                        {t("matrixPage.addTileModal.save")}
                    </Button>
                ),
            }}
            isOpen={isOpen}
            title={t("kanbanPage.addCompetence")}
        >
            <div>
                <TextField
                    multiline
                    label={t("matrixPage.addTileModal.description")}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#7F56D9",
                        },
                    }}
                    inputProps={{
                        style: {
                            height: "130px",
                        },
                    }}
                    value={description ?? ""}
                    className={styles.editableTextField}
                    onChange={handleInputChange}
                />
                <p className={styles.charactersCounter}>
                    {`${t("matrixPage.addTileModal.charsLeft")}: ${charactersLeft}`}
                </p>
            </div>
        </Modal>
    );
};
