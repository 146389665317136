// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Icon_icon__w6Pjo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon_xsmall__tDSbP {
  width: 12px;
  height: 12px;
}

.Icon_small__TQHr\\+ {
  width: 16px;
  height: 16px;
}

.Icon_medium__aPq3p {
  width: 20px;
  height: 20px;
}

.Icon_large__PX4PB {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Icon/Icon.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;AACJ","sourcesContent":[".icon {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.xsmall {\r\n    width: 12px;\r\n    height: 12px;\r\n}\r\n\r\n.small {\r\n    width: 16px;\r\n    height: 16px;\r\n}\r\n\r\n.medium {\r\n    width: 20px;\r\n    height: 20px;\r\n}\r\n\r\n.large {\r\n    width: 24px;\r\n    height: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Icon_icon__w6Pjo`,
	"xsmall": `Icon_xsmall__tDSbP`,
	"small": `Icon_small__TQHr+`,
	"medium": `Icon_medium__aPq3p`,
	"large": `Icon_large__PX4PB`
};
export default ___CSS_LOADER_EXPORT___;
