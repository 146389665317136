import React, { FC, useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { Competence } from "../../../api/types";

import { Button } from "../../Button/Button";

import Modal from "../../Modal/Modal";

import { createSingleCompetence } from "../../../store/Competences/competencesSlice";

import { AppDispatch } from "../../../store/store";

import { showNotification } from "../../../store/Notification/notificationSlice";

import styles from "./AddTileModal.module.scss";
import { AddTileModalProps } from "./AddTileModal.types";

export const AddTileModal: FC<AddTileModalProps> = ({
    matrixId,
    isOpen,
    item,
    toggleOpen,
    is_last_one,
    roles,
    areas,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const maxLength = 100;
    const [charactersLeft, setCharactersLeft] = useState<number>(maxLength);
    const [editedTile, setEditedTile] = useState<Competence | undefined>(undefined);

    useEffect(() => {
        if (editedTile?.description) {
            setCharactersLeft(maxLength - editedTile.description.length);
        }
    }, [editedTile]);

    const handleAddNewTile = () => {
        if (!editedTile) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Sukces!",
                    subtitle: "Opis i obszar są wymagane.",
                }),
            );
            return;
        }
        if (!editedTile?.description) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Sukces!",
                    subtitle: "Opis jest wymagany.",
                }),
            );
            return;
        }
        if (!editedTile?.area) {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Sukces!",
                    subtitle: "Obszary jest wymagany.",
                }),
            );
            return;
        }
        dispatch(
            createSingleCompetence({
                item: { ...item, ...editedTile },
                matrixId: matrixId,
                is_last_one: is_last_one,
            }),
        );
        toggleOpen();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        //@ts-ignore
        setEditedTile((prevTile: Competence) => ({
            ...prevTile,
            description: newValue,
        }));
        if (newValue.length <= maxLength) {
            setCharactersLeft(maxLength - newValue.length);
        }
    };

    const handleSelectChange = (e: SelectChangeEvent<number>) => {
        //@ts-ignore
        setEditedTile((prevTile: Competence) => ({
            ...prevTile,
            area: e.target.value,
        }));
    };

    const handleAreasSelectChange = (e: SelectChangeEvent<number[]>) => {
        const selectedOptions = e.target.value;

        //@ts-ignore
        setEditedTile((prevTile) => ({
            ...prevTile,
            attitudes: selectedOptions,
        }));
    };

    return (
        <Modal
            actionButtons={{
                onClose: {
                    action: () => {
                        toggleOpen();
                        setEditedTile(undefined);
                    },
                },
                onSubmit: (
                    <Button onClick={handleAddNewTile} variant="secondary">
                        {t("matrixPage.addTileModal.save")}
                    </Button>
                ),
            }}
            isOpen={isOpen}
            title={t("matrixPage.addTileModal.title")}
        >
            <div>
                <TextField
                    multiline
                    label={t("matrixPage.addTileModal.description")}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#7F56D9",
                        },
                    }}
                    inputProps={{
                        style: {
                            height: "130px",
                        },
                    }}
                    value={editedTile?.description ?? ""}
                    className={styles.editableTextField}
                    onChange={handleInputChange}
                />
                <p className={styles.charactersCounter}>
                    {`${t("matrixPage.addTileModal.charsLeft")}: ${charactersLeft}`}
                </p>
                <div className={styles.selectsContainer}>
                    <FormControl
                        sx={{
                            width: 300,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    color: "#7F56D9",
                                },
                            }}
                            id="roles-select-label"
                        >
                            {t("matrixPage.addTileModal.selectAreas")}
                        </InputLabel>
                        <Select
                            labelId="roles-select-label"
                            id="roles-select"
                            required
                            label={t("matrixPage.addTileModal.selectAreas")}
                            onChange={handleSelectChange}
                        >
                            {roles.map((competence) => (
                                <MenuItem key={competence.pk} value={competence.pk}>
                                    {competence.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        sx={{
                            width: 300,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    color: "#7F56D9",
                                },
                            }}
                            id="areas-select-label"
                        >
                            {t("matrixPage.addTileModal.selectAttitudes")}
                        </InputLabel>
                        <Select
                            labelId="areas-select-label"
                            id="areas-select"
                            multiple
                            value={editedTile?.attitudes ?? []}
                            onChange={handleAreasSelectChange}
                            label="Wybierz role"
                        >
                            {areas.map((area) => (
                                <MenuItem key={area.pk} value={area.pk}>
                                    {area.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Modal>
    );
};
