// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrapper__T-JYE {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  padding: 12px 14px;
}

.Header_wrapperLeft__Vj6sd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Header_title__JNmqs {
  font-size: 16px;
  font-weight: 600;
}

.Header_counter__WlRRP {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
}

.Header_wrapperRight__\\+MPG- {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/Column/Header/Header.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AADJ;;AAIA;EACI,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADJ","sourcesContent":["@use '../../../../styles/colors.scss' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-content: center;\r\n    justify-content: space-between;\r\n    border: 1px solid colors.$GRAY200;\r\n    border-radius: 12px;\r\n    padding: 12px 14px;\r\n}\r\n\r\n.wrapperLeft {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 10px;\r\n}\r\n\r\n.title {\r\n    font-size: 16px;\r\n    font-weight: 600;\r\n}\r\n\r\n.counter {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border: 1px solid colors.$GRAY200;\r\n    border-radius: 12px;\r\n    padding: 8px 12px;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n}\r\n\r\n.wrapperRight {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Header_wrapper__T-JYE`,
	"wrapperLeft": `Header_wrapperLeft__Vj6sd`,
	"title": `Header_title__JNmqs`,
	"counter": `Header_counter__WlRRP`,
	"wrapperRight": `Header_wrapperRight__+MPG-`
};
export default ___CSS_LOADER_EXPORT___;
