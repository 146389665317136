// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader_wrapper__73HPx {
  display: flex;
  flex-direction: column;
}

.PageHeader_title__qKudp {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 30px;
}

.PageHeader_subTitle__Z3Y3m {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  margin-top: 4px;
}

.PageHeader_badge__LsQf7 {
  display: flex;
  padding: 0 12px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #7A5AF8;
  background-color: #F4F3FF;
  border: 1px solid #BDB4FE;
  margin-left: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AADJ;;AAIA;EACI,aAAA;EACA,eAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,iBAAA;AADJ","sourcesContent":["@use '../../styles/colors' as colors;\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.title {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    font-weight: 600;\r\n    font-size: 30px;\r\n}\r\n\r\n.subTitle {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 4px;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    margin-top: 4px;\r\n}\r\n\r\n.badge {\r\n    display: flex;\r\n    padding: 0 12px;\r\n    height: 26px;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 16px;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    line-height: 20px;\r\n    color: #7A5AF8;\r\n    background-color: #F4F3FF;\r\n    border: 1px solid #BDB4FE;\r\n    margin-left: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PageHeader_wrapper__73HPx`,
	"title": `PageHeader_title__qKudp`,
	"subTitle": `PageHeader_subTitle__Z3Y3m`,
	"badge": `PageHeader_badge__LsQf7`
};
export default ___CSS_LOADER_EXPORT___;
