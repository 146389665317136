import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { createAiAttitude, deleteAiAttitude, editAiAttitude } from "../../api/requests/requests";
import { Attitude } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";

export const editBadge = createAsyncThunk(
    "aiBadges/editBadge",
    async (data: { id: number; badge: Attitude }, { dispatch }) => {
        try {
            return await editAiAttitude(data.id, data.badge);
        } catch (error) {
            let errorMsg = "Edycja zakończona niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const deleteBadge = createAsyncThunk(
    "aiBadges/deleteBadge",
    async (data: { id: number; badge: Attitude }, { dispatch }) => {
        try {
            return await deleteAiAttitude(data.id, data.badge);
        } catch (error) {
            let errorMsg = "Usuwanie zakończone niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const createBadge = createAsyncThunk(
    "aiBadges/createBadge",
    async (data: { id: number; badgeName: string }, { dispatch }) => {
        try {
            const res = await createAiAttitude(data.id, data.badgeName);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Generowanie zakończone powodzeniem.",
                }),
            );
            return res;
        } catch (error) {
            let errorMsg = "Edycja zakończona niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export interface AiBadgesState {
    badges: Attitude[];
    loading: boolean;
    error?: string;
    hasWebsocketError?: boolean;
}

export const initialState: AiBadgesState = {
    badges: [],
    loading: false,
};

const aiBadgesSlice = createSlice({
    name: "aiBadges",
    initialState,
    reducers: {
        clearBadges: (state) => {
            state.badges = [];
            state.loading = false;
        },
        setBadges: (state, action: PayloadAction<Attitude[]>) => {
            state.badges = action.payload;
        },
        setBadgesLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setIsWebsocketError: (state, action: PayloadAction<boolean>) => {
            state.hasWebsocketError = action.payload;
        }, // FUTURE
    },
    extraReducers: (builder) => {
        builder
            .addCase(editBadge.pending, (state) => {
                state.loading = true;
            })
            .addCase(editBadge.fulfilled, (state, action) => {
                state.loading = false;
                state.badges = state.badges.map((badge) => {
                    if (badge.pk === action.payload.pk) {
                        return action.payload;
                    }
                    return badge;
                });
            })
            .addCase(editBadge.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteBadge.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBadge.fulfilled, (state, action) => {
                state.loading = false;
                state.badges = state.badges.filter((badge) => badge.pk !== action.meta.arg.badge.pk);
            })
            .addCase(deleteBadge.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createBadge.pending, (state) => {
                state.loading = true;
            })
            .addCase(createBadge.fulfilled, (state, action) => {
                state.loading = false;
                state.badges = [...state.badges, action.payload];
            })
            .addCase(createBadge.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearBadges, setBadges, setBadgesLoading, setIsWebsocketError } = aiBadgesSlice.actions;
export default aiBadgesSlice.reducer;
