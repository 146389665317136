import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as Search } from "../../assets/icons/20x20/search.svg";

import styles from "./EmployeeFilters.module.scss";
import { EmployeeFiltersProps } from "./EmployeeFilters.types";

export const EmployeeFilters: FC<EmployeeFiltersProps> = ({
    inputFilter,
    setInputFilter,
    positionFilter,
    setPositionFilter,
    levelFilter,
    setLevelFilter,
    positions,
}) => {
    const { t } = useTranslation();
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputFilter(e.target.value);
    };

    const handlePositionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPositionFilter(e.target.value);
    };

    const handleLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLevelFilter(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.filterLabelWrapper}>
                <label className={styles.label} htmlFor="username">
                    {t("managmentPanel.table.employeeFilter.label")}
                </label>
                <div className={styles.inputWrapper}>
                    <span className={styles.inputIcon}>
                        <Search />
                    </span>
                    <input
                        onChange={handleInputChange}
                        className={styles.inputNames}
                        type="text"
                        id="username"
                        name="username"
                        placeholder={t("managmentPanel.table.employeeFilter.placeholder")}
                        value={inputFilter}
                    />
                </div>
            </div>
            <div className={styles.filterLabelWrapper}>
                <label className={styles.label} htmlFor="position">
                    {t("managmentPanel.table.positionFilter.label")}
                </label>
                <div className={styles.selectPositionWrapper}>
                    <select
                        onChange={handlePositionChange}
                        className={styles.filter}
                        id="position"
                        name="position"
                        value={positionFilter}
                    >
                        <option value="">{t("managmentPanel.table.positionFilter.placeholder")}</option>
                        {positions.map((position, i) => {
                            return (
                                <option key={i} value={position.name}>
                                    {position.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            {/* <div className={styles.filterLabelWrapper}>
                <label className={styles.label} htmlFor="level">
                    Poziom
                </label>
                <div className={styles.selectLevelWrapper}>
                    <select
                        onChange={handleLevelChange}
                        className={styles.filter}
                        id="level"
                        name="level"
                        value={levelFilter}
                    />
                </div>
            </div> */}
        </div>
    );
};
