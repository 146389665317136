import React from "react";

import styles from "./StepLine.module.scss";
import { StepLineProps } from "./StepLine.types";

export const StepLine = ({ current, size }: StepLineProps) => {
    return (
        <div className={styles.wrapper}>
            {Array.from({ length: size }, (_, index) => (
                <div className={current < index ? styles.normal : styles.active} key={index} />
            ))}
        </div>
    );
};
