import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getEmployees } from "../../api/requests/requests";
import { Employee } from "../../api/types";

export const fetchEmployees = createAsyncThunk("employees/getEmployees", async (id: number) => {
    const response = await getEmployees(id);
    return response;
});

export interface EmployeesState {
    employees: Employee[];
    loading: boolean;
    error?: string;
}

export const initialState: EmployeesState = {
    employees: [],
    loading: false,
    error: undefined,
};

const employeesSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        clearData: (state) => {
            state.employees = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployees.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEmployees.fulfilled, (state, action) => {
                state.loading = false;
                state.employees = action.payload;
            })
            .addCase(fetchEmployees.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearData } = employeesSlice.actions;
export default employeesSlice.reducer;
