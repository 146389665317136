import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { copyTemplate, getTemplates } from "../../api/requests/requests";
import { Template } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";
import { handleError } from "../../utils/handleError";

export interface TemplatesState {
    templates: Template[];
    loading: boolean;
    isCopying: boolean;
    error?: string;
}

export const initialState: TemplatesState = {
    templates: [],
    loading: false,
    isCopying: false,
    error: undefined,
};

export const fetchTemplates = createAsyncThunk("templates/getTemplates", async () => {
    const response = await getTemplates();
    return response;
});

export const copyModelFromTemplate = createAsyncThunk(
    "templates/copyModelFromTemplate",
    async (args: { template: Template }, { dispatch }) => {
        try {
            const res = await copyTemplate(args.template);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Model został dodany.",
                }),
            );
            return res;
        } catch (e) {
            handleError(e, dispatch);
        }
    },
);

const templatesSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {
        clearData: (state) => {
            state.templates = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplates.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTemplates.fulfilled, (state, action) => {
                state.loading = false;
                state.templates = action.payload;
            })
            .addCase(copyModelFromTemplate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(copyModelFromTemplate.pending, (state) => {
                state.isCopying = true;
            })
            .addCase(copyModelFromTemplate.fulfilled, (state) => {
                state.isCopying = false;
            })
            .addCase(fetchTemplates.rejected, (state, action) => {
                state.isCopying = false;
                state.error = action.error.message;
            });
    },
});

export const { clearData } = templatesSlice.actions;
export default templatesSlice.reducer;
