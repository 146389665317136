import React, { FC } from "react";

import { useDispatch } from "react-redux";

import { Formik, Form, Field, FieldProps } from "formik";

import { InputLabel, TextField } from "@mui/material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { AppDispatch } from "../../../store/store";

import { Button } from "../../Button/Button";

import { passwordResetConfirm } from "../../../store/LostAccount/lostAccountSlice";

import styles from "./StepTwo.module.scss";

export const StepTwo: FC<{ id: string }> = ({ id }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t("globals.validation.required")),
    });

    const handleSubmit = async (values: { password: string }) => {
        const res = await dispatch(passwordResetConfirm({ hash: id, password: values.password }));
        if (res.type === "lostAccount/passwordResetConfirm/fulfilled") {
            navigate("/login");
        }
    };

    return (
        <div className={styles.formContainer}>
            <Formik initialValues={{ password: "" }} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {() => (
                    <Form className={styles.form}>
                        <p className={styles.title}>{t("loginPage.lostAccount")}</p>
                        <Field name="password" data-testid="passwordInput">
                            {({ field }: FieldProps) => (
                                <div>
                                    <InputLabel id="password-label" className={styles.label}>
                                        {t("loginPage.newPassword")}
                                    </InputLabel>
                                    <TextField
                                        {...field}
                                        id="password-label"
                                        required
                                        type="password"
                                        className={styles.textField}
                                        sx={{
                                            "& .MuiInputBase-input": {
                                                padding: "10px 14px",
                                                height: "24px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Field>
                        <div className={styles.submitButtonContainer}>
                            <Button variant="secondary" size="medium" fullWidth type="submit">
                                {t("loginPage.resetPassword")}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
