import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ProtectedRoute } from "../utils/ProtectedRoute";

import { getUser } from "../store/Auth_User/userSlice";

import { AppDispatch, RootState } from "../store/store";

import { selectAccessToken } from "../store/Auth_User/authSlice";

import { LoginPage } from "./LoginPage/LoginPage";
import { RegisterPage } from "./RegisterPage/RegisterPage";
import { CreatePage } from "./CreatePage/CreatePage";
import { ManagmentPanelPage } from "./ManagmentPanelPage/ManagmentPanelPage";
import { KanbanPage } from "./KanbanPage/KanbanPage";
import { MatrixPage } from "./MatrixPage/MatrixPage";
import { PaymentPage } from "./PaymentPage/PaymentPage";
import { SuccessPage } from "./SuccessPage/SuccessPage";
import { ErrorPage } from "./ErrorPage/ErrorPage";
import { ExportPage } from "./ExportPage/ExportPage";
import { PasswordReset } from "./PasswordReset/PasswordReset";

const Index: React.FunctionComponent = () => {
    const { user } = useSelector((state: RootState) => state.userStore);
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (Object.keys(user).length === 0 && accessToken) {
            dispatch(getUser());
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/reset" element={<PasswordReset />} />
                <Route path="/reset/*" element={<PasswordReset />} />
                <Route
                    path="/matrixes"
                    element={
                        <ProtectedRoute>
                            <MatrixPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/export"
                    element={
                        <ProtectedRoute>
                            <ExportPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/create"
                    element={
                        <ProtectedRoute>
                            <CreatePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/managment"
                    element={
                        <ProtectedRoute>
                            <ManagmentPanelPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/payment"
                    element={
                        <ProtectedRoute>
                            <PaymentPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/success"
                    element={
                        <ProtectedRoute>
                            <SuccessPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/error"
                    element={
                        <ProtectedRoute>
                            <ErrorPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/kanban"
                    element={
                        <ProtectedRoute>
                            <KanbanPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<h1>Error 404, źle trafiłeś!</h1>} />
            </Routes>
        </Router>
    );
};

export default Index;
