import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../store/store";
import { fetchMatrixes } from "../../store/Matrixes/matrixesSlice";

import { setMatrixId, setMatrixName } from "../../store/CurrentMatrix/currentMatrixSlice";

import styles from "./Matrixes.module.scss";

export const Matrixes: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, matrixes } = useSelector((state: RootState) => state.matrixes);
    const { user } = useSelector((state: RootState) => state.userStore);

    useEffect(() => {
        dispatch(fetchMatrixes());
    }, [dispatch]);

    if (loading) return <CircularProgress data-testid="circularProgress" />;

    return (
        <div className={styles.wrapper}>
            {user.is_organization_admin && (
                <button onClick={() => navigate("/create")} className={styles.addNewMatrix}>
                    {t("homePage.addNewModal")}
                </button>
            )}
            <div className={styles.matrixesWrapper}>
                {matrixes.map((matrix) => {
                    return (
                        <button
                            key={matrix.id}
                            onClick={() => {
                                dispatch(setMatrixId(matrix.id));
                                dispatch(setMatrixName(matrix.name));
                            }}
                            className={styles.matrixButton}
                        >
                            {matrix.name}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
