import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import {
    createCompetenceGoal,
    createUserGoal,
    getColumns,
    moveCompetenceBeetwenColumns,
    moveUserGoalBeetwenColumns,
} from "../../../api/requests/requests";
import { Column, Competence } from "../../../api/types";
import { showNotification } from "../../Notification/notificationSlice";

export const fetchColumns = createAsyncThunk(
    "columns/getColumns",
    async (data?: { matrixId?: number; userId?: number }) => {
        const response = await getColumns(data?.matrixId, data?.userId);
        return response;
    },
);
export const moveCompetence = createAsyncThunk(
    "columns/moveCompetenceBeetwenColumns",
    async (data: { columnId: number; competence: Competence; matrixId?: number; userId?: number }) => {
        await moveCompetenceBeetwenColumns(data.competence.pk as number, data.columnId);
        return await getColumns(data?.matrixId, data?.userId);
    },
);

export const moveUserGoal = createAsyncThunk(
    "columns/moveUserGoalBeetwenColumns",
    async (data: { goalId: number; order: number; matrixId?: number; userId?: number }) => {
        await moveUserGoalBeetwenColumns(data.goalId, data.order);
        return await getColumns(data?.matrixId, data?.userId);
    },
);

export const createGoal = createAsyncThunk(
    "columns/createUserGoal",
    async (goal: { description: string; order: number }, { dispatch }) => {
        try {
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Error!",
                    subtitle: "Dodawanie kompetencji zakończone powodzeniem.",
                }),
            );
            return await createUserGoal(goal.description, goal.order);
        } catch (error) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const createGoalCompetence = createAsyncThunk(
    "columns/createCompetenceGoal",
    async (goal: { description: string; refCompetenceId: number }, { dispatch }) => {
        try {
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Error!",
                    subtitle: "Dodawanie kompetencji zakończone powodzeniem.",
                }),
            );
            return await createCompetenceGoal(goal.description, goal.refCompetenceId);
        } catch (error) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export interface ColumnsState {
    columns: Column[];
    loading: boolean;
    moving: boolean;
    error?: string;
}

export const initialState: ColumnsState = {
    columns: [],
    loading: false,
    moving: false,
    error: undefined,
};

const columnsSlice = createSlice({
    name: "columns",
    initialState,
    reducers: {
        clearData: (state) => {
            state.columns = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchColumns.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchColumns.fulfilled, (state, action) => {
                state.loading = false;
                state.columns = action.payload;
            })
            .addCase(fetchColumns.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(moveUserGoal.pending, (state) => {
                state.moving = true;
            })
            .addCase(moveUserGoal.fulfilled, (state, action) => {
                state.moving = false;
                state.columns = action.payload;
            })
            .addCase(moveUserGoal.rejected, (state, action) => {
                state.moving = false;
                state.error = action.error.message;
            })
            .addCase(moveCompetence.pending, (state) => {
                state.moving = true;
            })
            .addCase(moveCompetence.fulfilled, (state, action) => {
                state.moving = false;
                state.columns = action.payload;
            })
            .addCase(moveCompetence.rejected, (state, action) => {
                state.moving = false;
                state.error = action.error.message;
            })
            .addCase(createGoal.pending, (state) => {
                state.loading = true;
            })
            .addCase(createGoal.fulfilled, (state, action) => {
                const column = state.columns.find((col) => col.order === action.payload.order);

                state.loading = false;
                if (column) {
                    state.columns = state.columns.map((column) => {
                        if (column.order === action.payload.order) {
                            return {
                                ...column,
                                goals: [...column.goals, action.payload],
                            };
                        }
                        return column;
                    });
                }
            })
            .addCase(createGoal.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createGoalCompetence.pending, (state) => {
                state.loading = true;
            })
            .addCase(createGoalCompetence.fulfilled, (state, action) => {
                state.columns = state.columns.map((column) => {
                    const updatedMatrixes = column.matrixes.map((matrix) => {
                        const updatedCompetences = matrix.competences.map((competence) => {
                            if (competence.pk === action.payload.competence) {
                                return {
                                    ...competence,
                                    goals: competence.goals ? [...competence.goals, action.payload] : [action.payload],
                                };
                            }
                            return competence;
                        });

                        return {
                            ...matrix,
                            competences: updatedCompetences,
                        };
                    });

                    return {
                        ...column,
                        matrixes: updatedMatrixes,
                    };
                });
                state.loading = false;
            })
            .addCase(createGoalCompetence.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearData } = columnsSlice.actions;
export default columnsSlice.reducer;
