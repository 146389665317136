export const arabicToRoman = (title: string): string => {
    const core = title.split(" ")[0].replace(/_/g, " ");
    const num = title.split(" ")[1];

    switch (num) {
        case "1":
            return `${core} I`;
        case "2":
            return `${core} II`;
        case "3":
            return `${core} III`;
        default:
            return title;
    }
};
