import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import Modal from "../Modal/Modal";

import { toggleModal, setText } from "../../store/Modal/modalSlice";
import { AppDispatch, RootState } from "../../store/store";

import { editCompetenceDescription } from "../../store/Competences/competencesSlice";

import { Button } from "../Button/Button";

import styles from "./EditTileModal.module.scss";

const EditTileModal: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const { isOpen, item } = useSelector((state: RootState) => state.modal);
    const { areas, id, roles } = useSelector((state: RootState) => state.currentMatrix);

    const [selectedBadges, setSelectedBadges] = useState<Array<number>>([]);
    const [selectedArea, setSelectedArea] = useState<number>();

    useEffect(() => {
        if (item.attitudes && item.attitudes.length > 0) {
            setSelectedBadges(item.attitudes.map(Number));
        }
        if (item.area) {
            setSelectedArea(item.area);
        }
    }, [item.attitudes, item.area]);

    const handleEdit = () => {
        if (item && id) {
            dispatch(
                editCompetenceDescription({
                    item: {
                        ...item,
                        attitudes: selectedBadges.map(Number),
                        area: selectedArea,
                    },
                    matrixId: id,
                }),
            );
            dispatch(toggleModal(""));
        }
    };

    const handleAreasSelectChange = (e: SelectChangeEvent<number[]>) => {
        const selectedOptions = e.target.value as number[];

        setSelectedBadges(selectedOptions);
    };

    const handleSelectChange = (e: SelectChangeEvent<number>) => {
        const selectedOptions = e.target.value as number;

        setSelectedArea(selectedOptions);
    };

    return (
        <Modal
            isOpen={isOpen}
            actionButtons={{
                onClose: { action: () => dispatch(toggleModal("")) },
                onSubmit: (
                    <Button variant="secondary" onClick={handleEdit}>
                        {t("matrixPage.editTileModal.update")}
                    </Button>
                ),
            }}
            title={t("matrixPage.editTileModal.title")}
        >
            <div>
                <TextField
                    multiline
                    label={t("matrixPage.editTileModal.description")}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#7F56D9",
                        },
                    }}
                    inputProps={{
                        style: {
                            height: "130px",
                        },
                    }}
                    value={item.description}
                    className={styles.editableTextField}
                    onChange={(e) => dispatch(setText(e.target.value))}
                />
                <div className={styles.selectsContainer}>
                    <FormControl
                        sx={{
                            width: 300,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    color: "#7F56D9",
                                },
                            }}
                            id="roles-select-label"
                        >
                            {t("matrixPage.editTileModal.selectAreas")}
                        </InputLabel>
                        <Select
                            labelId="roles-select-label"
                            id="roles-select"
                            label={t("matrixPage.editTileModal.selectAreas")}
                            value={selectedArea ?? ""}
                            onChange={handleSelectChange}
                        >
                            {roles.map((competence) => (
                                <MenuItem key={competence.pk} value={competence.pk}>
                                    {competence.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        sx={{
                            width: 300,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#7F56D9",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    >
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    color: "#7F56D9",
                                },
                            }}
                            id="areas-select-label"
                        >
                            {t("matrixPage.editTileModal.selectAttitudes")}
                        </InputLabel>
                        <Select
                            labelId="areas-select-label"
                            id="areas-select"
                            multiple
                            value={selectedBadges ?? []}
                            onChange={handleAreasSelectChange}
                            label={t("matrixPage.editTileModal.selectAttitudes")}
                        >
                            {areas.map((area) => (
                                <MenuItem key={area.pk} value={area.pk}>
                                    {area.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Modal>
    );
};

export default EditTileModal;
