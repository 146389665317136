import React, { FC } from "react";

import styles from "./Counter.module.scss";
import { CouterProps } from "./Counter.types";

export const Counter: FC<CouterProps> = ({ counter, max }) => {
    return (
        <div className={styles.wrapper}>
            {counter} / {max}
        </div>
    );
};
