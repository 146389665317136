import React from "react";
import { PDFViewer, Document, Page, View, Text, StyleSheet, Font, Image } from "@react-pdf/renderer";

import Logo from "../../assets/pngs/logo.png";
import { mergeCompetencesInLevels } from "../../utils/mapper";

import { PrintableMatrixPDFProps } from "./PrintableMatrixPDF.types";

Font.register({
    family: "Ubuntu",
    fonts: [
        {
            src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
        },
        {
            src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
            fontWeight: "bold",
        },
        {
            src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
            fontWeight: "normal",
            fontStyle: "italic",
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        padding: 8,
    },
    titleWrapper: {
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        padding: 12,
        marginBottom: 8,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 12,
    },
    levelName: {
        fontSize: 16,
        fontWeight: "bold",
        marginRight: 10,
        marginLeft: 14,
    },
    itemsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
    },
    tile: {
        display: "flex",
        fontFamily: "Ubuntu",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: 8,
        padding: 10,
        width: 190,
        height: 190,
        fontSize: 18,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#CCCCCC",
    },
    tileImage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        padding: 10,
        width: 190,
        height: 190,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#CCCCCC",
    },
    image: {
        width: "100%",
        height: 100,
    },
    itemsLegendContainer: {
        display: "flex",
        fontFamily: "Ubuntu",
        alignItems: "center",
        flexDirection: "column",
    },
    itemsLegendInnerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 2,
        margin: "24px 0 0 0",
    },
    singleLegendRow: {
        display: "flex",
        alignItems: "center",
    },
    button: {
        display: "flex",
        fontFamily: "Ubuntu",
        textAlign: "center",
        borderRadius: 8,
        padding: 10,
        width: 190,
        height: 190,
        fontSize: 18,
        borderWidth: 1,
    },
});

const PrintableMatrixPDF: React.FC<PrintableMatrixPDFProps> = ({ levels, roles, fileName }) => {
    const levelsData = mergeCompetencesInLevels(levels);

    const getColor = (area?: number) => {
        const colors = roles.find((role) => role.pk === area);
        return colors?.background_color ?? "white";
    };

    return (
        <Document title={fileName}>
            <Page size="A4" style={styles.page}>
                <View style={styles.itemsLegendContainer}>
                    <View style={styles.itemsLegendInnerContainer}>
                        {roles.map((item, i) => (
                            <Text
                                key={i}
                                style={{
                                    color: item.text_color,
                                    backgroundColor: item.background_color,
                                    borderColor: item.border_color,
                                    ...styles.button,
                                }}
                            >
                                {item.name}
                            </Text>
                        ))}
                    </View>
                </View>
            </Page>
            {levelsData.map((level, i) => (
                <Page key={i} size="A4" style={styles.page}>
                    <View>
                        <View style={styles.titleWrapper}>
                            <Text style={styles.levelName}>{level.name.split(" ")[0]}</Text>
                        </View>
                        <View style={styles.itemsContainer}>
                            {level.competences[0].map((item) =>
                                item.description === "logo" ? (
                                    <View style={styles.tileImage} key={item?.pk}>
                                        <Image src={Logo} style={styles.image} />
                                    </View>
                                ) : (
                                    <Text
                                        style={{
                                            ...styles.tile,
                                            backgroundColor: getColor(item.area),
                                        }}
                                        key={item?.pk}
                                    >
                                        {item.description}
                                    </Text>
                                ),
                            )}
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    );
};

const PrintableMatrixPDFViewer: React.FC<PrintableMatrixPDFProps> = ({ levels, roles, fileName }) => (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <PrintableMatrixPDF fileName={fileName} levels={levels} roles={roles} />
    </PDFViewer>
);

export default PrintableMatrixPDFViewer;
