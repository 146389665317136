import React, { FC } from "react";
import { Modal as ModalMUI } from "@mui/material";

import styles from "./Modal.module.scss";
import { ModalProps } from "./Modal.types";

const Modal: FC<ModalProps> = ({ isOpen, children, actionButtons, title, subTitle }) => {
    return (
        <ModalMUI className={styles.modalContainer} open={isOpen} onClose={actionButtons.onClose.action}>
            <div className={styles.modalInnerContainer}>
                <p className={styles.title}>{title}</p>
                {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
                <div className={styles.childrenContainer}>{children}</div>
                <div className={styles.actionButtonsWrapper}>
                    {actionButtons.onClose.button && actionButtons.onClose.button}
                    <div className={styles.submitButtonContainer}>{actionButtons.onSubmit}</div>
                </div>
            </div>
        </ModalMUI>
    );
};

export default Modal;
