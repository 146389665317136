import React, { FC } from "react";

import { CompetenceProps } from "./Competence.types";
import styles from "./Competence.module.scss";

const Competence: FC<CompetenceProps> = ({
    item: { background_color, border_color, text_color, name, count_user_competence },
    handleClick,
    checked,
}) => {
    return (
        <button
            onClick={handleClick}
            style={{
                color: checked ? text_color : "#344054",
                border: checked ? `1px solid ${border_color}` : "1px solid #EAECF0",
                background: checked ? background_color : undefined,
            }}
            className={`${styles.button} ${checked ? styles.checked : styles.normal}`}
        >
            <span className={styles.text}>{name}</span>
            <span className={styles.counter}>{count_user_competence}</span>
        </button>
    );
};

export default Competence;
