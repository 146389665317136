import { isAxiosError } from "axios";

import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";

import { showNotification } from "../store/Notification/notificationSlice";

export const handleError = (e: any, dispatch: ThunkDispatch<unknown, unknown, UnknownAction>) => {
    let errorMsg = "Wystąpił błąd. Spróbuj później.";
    if (isAxiosError(e) && e?.response?.data?.detail) {
        errorMsg = e.response.data.detail;
    }

    dispatch(
        showNotification({
            variant: "error",
            title: "Error!",
            subtitle: errorMsg,
        }),
    );

    console.error(e);
};
