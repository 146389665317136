// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/fonts/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/fonts/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Inter;
    src: URL(${___CSS_LOADER_URL_REPLACEMENT_0___}/../public/fonts/Inter-Regular.ttf);
}

@font-face {
    font-family: Inter;
    src: URL(${___CSS_LOADER_URL_REPLACEMENT_1___}/../public/fonts/Inter-Medium.ttf);
}

@font-face {
    font-family: Inter;
    src: URL(${___CSS_LOADER_URL_REPLACEMENT_2___}/../public/fonts/Inter-SemiBold.ttf);
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.scss"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,8EAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,6EAA6C;AACjD;;AAEA;IACI,kBAAkB;IAClB,+EAA+C;AACnD","sourcesContent":["@font-face {\r\n    font-family: Inter;\r\n    src: URL(../../public/fonts/Inter-Regular.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: Inter;\r\n    src: URL(../../public/fonts/Inter-Medium.ttf);\r\n}\r\n\r\n@font-face {\r\n    font-family: Inter;\r\n    src: URL(../../public/fonts/Inter-SemiBold.ttf);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
