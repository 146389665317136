import React, { FC, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useTranslation } from "react-i18next";

import { CopyToClipboardProps } from "./CopyToClipboard.types";

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, textToCopy }) => {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <Box display="flex" alignItems="center">
            <Typography variant="body1">{text}</Typography>
            <IconButton onClick={handleCopy} aria-label="copy to clipboard">
                <ContentCopyIcon fontSize="small" />
            </IconButton>
            {copied && (
                <Typography variant="body2" color="success.main">
                    {t("managmentPanel.copied")}
                </Typography>
            )}
        </Box>
    );
};
