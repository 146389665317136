import axios from "axios";

import {
    LoginCredentials,
    Register as RegisterData,
    CompetencesArea,
    Attitude,
    Level,
    MovingCompetence,
    Competence,
    MovingCompetenceResponse,
    MarkingCompetenceResponse,
    Profile,
    Matrix,
    Status,
    Employee,
    Organization,
    Column,
    Goal,
    GoalData,
    Areas,
    Attitudes,
    RegisterCredentials,
    Tokens,
    LearningCompetenceResponse,
    Table,
    ManagmentPanelTableEmployeesData,
    UserGoal,
    CompetenceGoal,
    MatrixGenerationStatus,
    MatrixJobLevelConfiguration,
} from "../types";
import { URLS } from "../urls";
import api from "../../store/Auth_User/authService";

export const Register = (data: RegisterCredentials): Promise<RegisterData> =>
    axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_BASEURL}${URLS.REGISTER}`,
        data,
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        });

export const Login = (data: LoginCredentials): Promise<Tokens> =>
    api
        .post(URLS.LOGIN, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const changeLanguage = (lang: string): Promise<{ message: string }> =>
    api
        .post(URLS.LANGUAGE, {
            language: lang,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const resetPassword = (email: string): Promise<{ message: string }> =>
    api
        .post(URLS.PASSWORD_RESET, {
            email: email,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const resetPasswordConfirm = (password: string, hash: string): Promise<Status> =>
    api
        .post(`${URLS.PASSWORD_RESET_CONFIRM}${hash}`, {
            new_password: password,
        })
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });

export const getProfile = (): Promise<Profile> =>
    api
        .get(URLS.PROFILE)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getMatrixes = (): Promise<Matrix[]> =>
    api
        .get(URLS.MATRIX)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getRoles = (id: number): Promise<Areas> =>
    api
        .get(`${URLS.MATRIX}${id}/area/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getAttitudes = (id: number): Promise<Attitudes> =>
    api
        .get(`${URLS.MATRIX}${id}/attitude/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getCompetencesTable = (id: number): Promise<Table> =>
    api
        .get(`${URLS.MATRIX}${id}/table/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getEmployeeCompetencesTable = (id: number, userId: number): Promise<Level[]> =>
    api
        .get(`${URLS.MATRIX}${id}/users/${userId}/competence/table/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editCompetence = (matrixId: number, item: Competence): Promise<Competence> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/competence/${item.pk}/`, {
            description: item.description,
            attitudes: item.attitudes,
            area: item.area,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const deleteCompetence = (matrixId: number, id: number): Promise<Status> => {
    return api
        .delete(`${URLS.MATRIX}${matrixId}/competence/${id}/`)
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });
};

export const duplicateCompetence = (matrixId: number, itemId: number): Promise<Competence> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/competence/${itemId}/duplicate/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const postCompetence = (matrixId: number, item: Competence): Promise<Competence> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/competence/`, {
            area: item.area,
            attitudes: item.attitudes,
            description: item.description,
            additional_info: item.additional_info,
            column_index: item.column_index,
            row_index: item.row_index,
            level: item.level,
            learning_requirement_status: 0, // "This field is required."
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editCompetenceInfoField = (matrixId: number, id: number, additional_info: string): Promise<Competence> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/competence/${id}/`, {
            additional_info: additional_info,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editCompetencePosition = (matrixId: number, data: MovingCompetence): Promise<MovingCompetenceResponse> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/competence/${data.id}/position/`, {
            level: data.level,
            row_index: data.row_index,
            column_index: data.column_index,
            competence: data.id_taken_spot,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const markCompetence = (
    matrixId: number,
    competenceId: number,
    learning_requirement_status: number,
): Promise<MarkingCompetenceResponse> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/competence/${competenceId}/`, {
            learning_requirement_status: learning_requirement_status,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const learnCompetence = (
    matrixId: number,
    competenceId: number,
    learning_status: boolean,
): Promise<LearningCompetenceResponse> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/competence/${competenceId}/`, {
            learning_status: learning_status,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const addMatrix = (data: FormData): Promise<Organization> =>
    api
        .post(URLS.MATRIX, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editAiArea = (matrixId: number, area: CompetencesArea): Promise<CompetencesArea> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/area/${area.pk}/`, {
            name: area.name,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const createAiArea = (matrixId: number, areaName: string): Promise<CompetencesArea> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/area/`, {
            name: areaName,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const deleteAiArea = (matrixId: number, area: CompetencesArea): Promise<Status> =>
    api
        .delete(`${URLS.MATRIX}${matrixId}/area/${area.pk}/`)
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });

export const getAiCompetences = (id: number): Promise<Status> =>
    api
        .post(`${URLS.GENERATE}${id}/matrix/competences/`)
        .then((response) => {
            return { status: response.status };
        })
        .catch((error) => {
            throw error;
        });

export const getMatrixGenerationStatus = (matrixId: number): Promise<MatrixGenerationStatus> =>
    api
        .get(`${URLS.MATRIX}${matrixId}/generation-status/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editAiAttitude = (matrixId: number, attitude: Attitude): Promise<Attitude> =>
    api
        .patch(`${URLS.MATRIX}${matrixId}/attitude/${attitude.pk}/`, {
            name: attitude.name,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const createAiAttitude = (matrixId: number, attitudeName: string): Promise<Attitude> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/attitude/`, {
            name: attitudeName,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const deleteAiAttitude = (matrixId: number, attitude: Attitude): Promise<Status> =>
    api
        .delete(`${URLS.MATRIX}${matrixId}/attitude/${attitude.pk}/`)
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });

export const getEmployees = (goalId: number): Promise<Employee[]> =>
    api
        .get(`${URLS.SKILLS_USER_GOAL}${goalId}/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getColumns = (matrixId?: number, userId?: number): Promise<Column[]> =>
    api
        .get(`/skills/table/`, {
            params: {
                matrix_pk: matrixId,
                user_pk: userId,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const moveCompetenceBeetwenColumns = (competenceId: number, columnId: number): Promise<Column[]> =>
    api
        .patch(`/skills/competence/${competenceId}/`, {
            table_column: columnId,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const moveUserGoalBeetwenColumns = (goalId: number, order: number): Promise<UserGoal> =>
    api
        .patch(`${URLS.SKILLS_USER_GOAL}${goalId}/`, {
            order: order,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const createUserGoal = (description: string, order: number, refCompetenceId?: number): Promise<UserGoal> =>
    api
        .post(`${URLS.SKILLS_USER_GOAL}`, {
            description: description,
            order: order,
            competence: refCompetenceId,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const createCompetenceGoal = (description: string, refCompetenceId: number): Promise<CompetenceGoal> =>
    api
        .post(`${URLS.SKILLS_USER_GOAL}`, {
            description: description,
            competence: refCompetenceId,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const getGoals = (matrixId: number): Promise<Goal[]> =>
    api
        .get(`${URLS.SKILLS}${matrixId}/goal/`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const createGoal = (goal: GoalData): Promise<Goal> =>
    api
        .post(`${URLS.SKILLS}${goal.matrixPk}/goal/`, {
            development_goal: goal.development_goal,
            deadline: goal.deadline,
            user: goal.user,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const editGoal = (goal: Goal): Promise<Goal> =>
    api
        .patch(`${URLS.SKILLS}${goal.matrix.pk}/goal/${goal.pk}/`, {
            development_goal: goal.development_goal,
            deadline: goal.deadline,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const assignUserToMatrix = (matrixId: number, userEmail: string): Promise<any> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/assign_users_to_matrix/`, {
            users: [userEmail],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const unassignUserToMatrix = (matrixId: number, userEmail: string): Promise<any> =>
    api
        .post(`${URLS.MATRIX}${matrixId}/unassign_users_from_matrix/`, {
            users: [userEmail],
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });

export const fetchEmployeesManagmentPanel = (page?: number): Promise<ManagmentPanelTableEmployeesData> => {
    const url = page ? `${URLS.ORGANIZATION_ADMIN_PANEL}?page=${page}` : `${URLS.ORGANIZATION_ADMIN_PANEL}`;

    return api
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteUser = (userId: number): Promise<Status> => {
    return api
        .delete(`${URLS.ORGANIZATION}remove_user/${userId}/`)
        .then((response) => {
            return {
                status: response.status,
            };
        })
        .catch((error) => {
            throw error;
        });
};

export const buyPlan = (planId: number): Promise<{ url: string }> => {
    return api
        .post(URLS.BUY_PLAN, {
            plan: planId,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getMatrixJobLevels = (): Promise<MatrixJobLevelConfiguration[]> =>
    api
        .get(`${URLS.MATRIX_CONFIGURATION}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
