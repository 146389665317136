import React, { FC, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
} from "@mui/material";

import Slider from "react-slick";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Badge from "../Badge/Badge";
import EditTileModal from "../EditTileModal/EditTileModal";

import { DraggableGrid } from "../DragableGrid/DragableGrid";

import { fetchLevels } from "../../store/Competences/competencesSlice";
import { AppDispatch, RootState } from "../../store/store";

import {
    clearGenerationStatus,
    fetchAttitudes,
    fetchRoles,
    getGenerationStatus,
} from "../../store/CurrentMatrix/currentMatrixSlice";

import { Matrixes } from "../Matrixes/Matrixes";

import InfoTileModal from "../InfoTileModal/InfoTileModal";

import Competence from "../Competence/Competence";

import { SectionHeader } from "../SectionHeader/SectionHeader";

import { generateLevelsData } from "../../utils/mapper";

import { DraggableGridVertical } from "../DragableGridVertical/DragableGridVertical";

import { setCreateMatixId, setStep } from "../../store/AiMatrix/createMatrixSlice";

import { setAreas } from "../../store/AiMatrix/aiAreasSlice";

import { CompetencesArea } from "../../api/types";

import { Button } from "../Button/Button";

import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";

import { fetchCourses } from "../../store/Courses/coursesSlice";

import styles from "./Matrix.module.scss";
import { MatrixProps } from "./Matrix.types";

export const Matrix: FC<MatrixProps> = ({ isEmployeeMatrix }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { isMarking, levels, loading } = useSelector((state: RootState) => state.competences);
    const { areas, areasLoading, rolesLoading, roles, id, name, generation } = useSelector(
        (state: RootState) => state.currentMatrix,
    );
    const { matrixes } = useSelector((state: RootState) => state.matrixes);
    let sliderRef = useRef<Slider | null>(null) as unknown as Slider | null; // https://react-slick.neostack.com/docs/example/slick-go-to

    const [indexCurrentSlide, setIndexCurrentSlide] = useState<number>(0);
    const [isHorizontal, setIsHorizontal] = useState<boolean>(false);

    const [filters, setFilters] = useState<{
        tiles: Array<number>;
        areas: Array<number>;
        learned: "learned" | "all" | "notLearned";
    }>({
        tiles: [],
        areas: [],
        learned: "all",
    });

    useEffect(() => {
        let refreshTimeout: NodeJS.Timeout;
        if (levels.length === 0 && id && !isEmployeeMatrix) {
            refreshTimeout = setTimeout(() => {
                dispatch(getGenerationStatus({ matrixId: id })).then((v) => {
                    //@ts-ignore
                    if (v.payload.completion_fraction === 100) {
                        dispatch(fetchLevels(id));
                        dispatch(clearGenerationStatus());
                    }
                });
            }, 0.25 * 60000);
        }

        return () => {
            clearTimeout(refreshTimeout);
        };
    }, [levels, generation]);

    useEffect(() => {
        if (id && !isEmployeeMatrix) {
            let areas: CompetencesArea[] = [];
            dispatch(fetchLevels(id));
            dispatch(fetchCourses(id));
            // has to be in .then cuz of no status data from BE
            dispatch(fetchRoles(id)).then((e) => {
                //@ts-ignore 'e.payload' is of type 'unknown'.
                if (e.type === "currentMatrix/fetchRoles/fulfilled" && e.payload?.areas.length === 0) {
                    dispatch(setStep(1));
                    dispatch(setCreateMatixId(id));
                    navigate("/create");
                    return;
                }
                //@ts-ignore 'e.payload' is of type 'unknown'.
                areas = e.payload?.areas;
                dispatch(fetchAttitudes(id)).then((e) => {
                    //@ts-ignore 'e.payload' is of type 'unknown'.
                    if (e.type === "currentMatrix/fetchAttitudes/fulfilled" && e.payload.attitudes.length === 0) {
                        dispatch(setAreas(areas));
                        dispatch(setStep(2));
                        dispatch(setCreateMatixId(id));
                        navigate("/create");
                    }
                });
            });

            setIndexCurrentSlide(0);
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (id && !isMarking && !isEmployeeMatrix) {
            dispatch(fetchRoles(id));
        }
    }, [dispatch, isMarking]);

    useEffect(() => {
        if (isEmployeeMatrix && id) {
            dispatch(fetchRoles(id));
            dispatch(fetchAttitudes(id));
        }
    }, [dispatch, isMarking]);

    useEffect(() => {
        setFilters({
            tiles: [...roles.map((el) => el.pk)],
            areas: [...areas.map((el) => el.pk)],
            learned: "all",
        });
    }, [areas, roles]);

    const setSliderRef = (slider: Slider | null) => {
        sliderRef = slider;
    };

    const handleTileFilter = (pk: number) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            tiles: prevFilters.tiles.includes(pk)
                ? prevFilters.tiles.filter((filter) => filter !== pk)
                : [...prevFilters.tiles, pk],
        }));
    };

    const handleBadgeFilter = (type: number) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            areas: prevFilters.areas.includes(type)
                ? prevFilters.areas.filter((filter) => filter !== type)
                : [...prevFilters.areas, type],
        }));
    };

    const setLearnedFilter = (event: SelectChangeEvent<"learned" | "all" | "notLearned">) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            learned: event.target.value as "learned" | "all" | "notLearned",
        }));
    };

    const renderAttitudes = () => (
        <div className={styles.itemsLegendContainer}>
            <SectionHeader title={t("matrixPage.areas")} subTitle="" titleSize="huge" />
            {rolesLoading ? (
                <CircularProgress style={{ minHeight: 60 }} />
            ) : (
                <ul className={styles.itemsLegendInnerContainer}>
                    {roles.map((item, i) => (
                        <li className={styles.singleLegendRow} key={i}>
                            <Competence
                                item={item}
                                handleClick={() => handleTileFilter(item.pk)}
                                checked={!!filters.tiles.includes(item.pk)}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    const handleChange = () => {
        setIsHorizontal((prev) => !prev);
    };

    const renderAreas = () => (
        <div className={styles.areasLegendContainer}>
            <SectionHeader title={t("matrixPage.attitudes")} subTitle="" titleSize="huge" />
            {areasLoading ? (
                <CircularProgress style={{ minHeight: 60 }} />
            ) : (
                <ul className={styles.areasLegendInnerContainer}>
                    {areas.map((badge, i) => (
                        <li key={i}>
                            <Badge
                                item={badge}
                                handleClick={() => handleBadgeFilter(badge.pk)}
                                checked={!!filters.areas.includes(badge.pk)}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    const renderMatrixName = () => {
        const title = matrixes.find((matrix) => matrix.id === id)?.name ?? (name ? name : t("matrixPage.modelName"));
        return <SectionHeader title={title} subTitle="" titleSize="huge" />;
    };

    const levelsData = generateLevelsData(levels);

    const getFirstRolledColumn = () => {
        let titlesWithPositions: { name: string; position: number }[] = [];

        const dataToCheck = levelsData.slice(0, indexCurrentSlide);
        if (dataToCheck) {
            const objectsWithOne = dataToCheck.filter((obj) => obj.name.includes(" 1"));
            titlesWithPositions = objectsWithOne.map((obj) => ({
                name: obj.name,
                position: levelsData.indexOf(obj),
            }));

            titlesWithPositions = titlesWithPositions.filter(
                (tile) => !tile.name.includes(levelsData[indexCurrentSlide].name.split(" ")[0]),
            );
        }
        return titlesWithPositions;
    };

    const getSecondRolledColumn = () => {
        const counter = indexCurrentSlide + 4;
        const dataToCheck = levelsData.slice(counter);
        let titlesWithPositions: { name: string; position: number }[] = [];

        if (dataToCheck) {
            const objectsWithOne = dataToCheck.filter((obj) => obj.name.includes(" 1"));

            titlesWithPositions = objectsWithOne.map((obj) => ({
                name: obj.name.split(" ")[0],
                position: levelsData.indexOf(obj),
            }));
        }
        return titlesWithPositions;
    };

    const firstColumn = getFirstRolledColumn();
    const secondColumn = getSecondRolledColumn();
    const shouldRenderColumn = levelsData.length > 4;

    if (!id) {
        return <Matrixes />;
    }

    // if (levelsData.length > 0 && levelsData.length < 4 && !loading) {
    //     return <p className={styles.notGeneratedError}>{t("matrixPage.notGeneratedError")}</p>;
    // }

    return (
        <div className={styles.container}>
            <div className={styles.matrixNameContainer}>{renderMatrixName()}</div>

            <div className={styles.filtersContainer}>
                {renderAttitudes()}
                {renderAreas()}
            </div>
            <EditTileModal />
            <InfoTileModal />
            <div className={styles.secondMenuContainer}>
                {shouldRenderColumn && (
                    <div className={styles.switchContainer}>
                        <p style={{ marginRight: 24 }}>{t("matrixPage.vertical")}</p>
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                            color: "#7F56D9",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                            backgroundColor: "#7F56D9",
                                        },
                                        "& .MuiSwitch-switchBase": {
                                            color: "#7F56D9",
                                        },
                                        "& .MuiSwitch-track": {
                                            backgroundColor: "#7F56D9",
                                        },
                                    }}
                                    checked={isHorizontal}
                                    onChange={handleChange}
                                />
                            }
                            label=""
                        />
                        <p>{t("matrixPage.horizontally")}</p>
                    </div>
                )}

                <Button
                    style={{ textWrap: "nowrap", marginLeft: "64px" }}
                    variant="tertiary"
                    onClick={() => navigate("/export")}
                >
                    {t("matrixPage.exportToPDF")}
                </Button>
                <FormControl
                    sx={{
                        width: 300,
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#7F56D9",
                            },
                            "&:hover fieldset": {
                                borderColor: "#7F56D9",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                    }}
                >
                    <InputLabel
                        sx={{
                            "&.Mui-focused": {
                                color: "#7F56D9",
                            },
                        }}
                        htmlFor="filter-learned"
                    >
                        {t("matrixPage.learnedFiltr.label")}
                    </InputLabel>
                    <Select
                        labelId="filter-learned"
                        value={filters.learned}
                        onChange={setLearnedFilter}
                        label="Filtruj po statusie kompetencji"
                        sx={{
                            height: "42px",
                            "& .MuiSelect-select": {
                                padding: "0px 12px",
                            },
                        }}
                    >
                        <MenuItem value="all">{t("matrixPage.learnedFiltr.first")}</MenuItem>
                        <MenuItem value="learned">{t("matrixPage.learnedFiltr.second")}</MenuItem>
                        <MenuItem value="notLearned">{t("matrixPage.learnedFiltr.third")}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {levelsData.length === 0 ? (
                        <div className={styles.generatingInfo}>
                            <CircularProgressWithLabel value={generation?.completion_fraction ?? 0} />
                            <p className={styles.generatingText}>{t("matrixPage.generateSuccessMsg")}</p>
                        </div>
                    ) : isHorizontal ? (
                        <div className={styles.draggableGridVerticalContainer}>
                            <DraggableGridVertical filters={filters} />
                        </div>
                    ) : (
                        <div className={styles.sliderColumnsContainer}>
                            {shouldRenderColumn && (
                                <div className={firstColumn.length > 1 ? styles.leftColumnMultiple : styles.leftColumn}>
                                    {firstColumn.reverse().map((column, i) => {
                                        const columnName = column.name.split(" ")[0].replace(/_/g, " ");
                                        return (
                                            <button
                                                className={styles.columnButton}
                                                key={i}
                                                onClick={() => sliderRef?.slickGoTo(column.position)}
                                            >
                                                {columnName}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                            <div className={styles.draggableGridContainer}>
                                <DraggableGrid
                                    filters={filters}
                                    setSliderRef={setSliderRef}
                                    indexCurrentSlide={indexCurrentSlide}
                                    setIndexCurrentSlide={setIndexCurrentSlide}
                                    shouldRenderColumn={shouldRenderColumn}
                                />
                            </div>
                            {shouldRenderColumn && (
                                <div
                                    className={
                                        secondColumn.length > 1 ? styles.rightColumnMultiple : styles.rightColumn
                                    }
                                >
                                    {secondColumn.reverse().map((column, i) => {
                                        const columnName = column.name.split(" ")[0].replace(/_/g, " ");
                                        return (
                                            <button
                                                className={styles.columnButton}
                                                key={i}
                                                onClick={() => {
                                                    if (levelsData.length - 1 === column.position) {
                                                        sliderRef?.slickGoTo(column.position - 3);
                                                    } else {
                                                        sliderRef?.slickGoTo(column.position);
                                                    }
                                                }}
                                            >
                                                {columnName}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
