import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";

import { AppDispatch, RootState } from "../../store/store";

import { fetchEmployees } from "../../store/Employees/employeesSlice";

import { setId } from "../../store/CurrentTab/currentTabSlice";

import { Navbar } from "../../components/Navbar/Navbar";

import { PageHeader } from "../../components/PageHeader/PageHeader";

import { Button } from "../../components/Button/Button";
import { SectionHeader } from "../../components/SectionHeader/SectionHeader";
import { ReactComponent as Plus } from "../../assets/icons/20x20/plus.svg";

import { showNotification } from "../../store/Notification/notificationSlice";

import { ActionButton } from "../../components/Navbar/Navbar.types";

import { EmployeeTable } from "../../components/EmployeeTable/EmployeeTable";

import { Pagination } from "../../components/Pagination/Pagination";
import { fetchManagmentEmployees, setPage } from "../../store/EmployeesManagmentPanel/EmployeesManagmentPanel.slice";

import { AssignUserToModelModal } from "../../components/AssignUserToModelModal/AssignUserToModelModal";

import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";

import { Matrix } from "../../components/Matrix/Matrix";

import { setMatrixId } from "../../store/CurrentMatrix/currentMatrixSlice";

import { KanbanBoard } from "../../components/KanbanBoard/KanbanBoard";

import { CURRENT_TAB_ID } from "../../utils/managmentPanelUrls";

import styles from "./ManagmentPanelPage.module.scss";

export const ManagmentPanelPage: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const { currentPage, totalPages, isPrev, isNext, loading, employees } = useSelector(
        (state: RootState) => state.employeesManagment,
    );

    const { user } = useSelector((state: RootState) => state.userStore);
    const { id: tabId } = useSelector((state: RootState) => state.currentTab);
    const { id } = useSelector((state: RootState) => state.currentTab);
    const { matrixes } = useSelector((state: RootState) => state.matrixes);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setId(0));
    }, []);

    const handleBack = () => {
        if (tabId === CURRENT_TAB_ID.MATRIX) {
            navigate("/managment");
            dispatch(setId(0));
            dispatch(setMatrixId(null));
        } else if (tabId === CURRENT_TAB_ID.KANBAN) {
            navigate("/managment");
            dispatch(setId(0));
        } else {
            navigate("/matrixes");
        }
    };

    const handleAddClick = () => {
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: "Opcja jest jeszcze niedostępna.",
            }),
        );
    };

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ];

    useEffect(() => {
        dispatch(fetchManagmentEmployees());
    }, []);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchManagmentEmployees(value));
    };
    const code = `\n ${t("managmentPanel.organizationCode")} ${user.organization_uuid}`;

    const getPlanName = (id: number) => {
        switch (id) {
            case 0:
                return "FREE";
            case 10:
                return "PROFESSIONAL";
            case 20:
                return "ENTERPRISE";
        }
    };

    return (
        <>
            <Navbar actionButtons={actionButtons} showPayment />
            <Layout>
                <div className={styles.wrapper}>
                    <div className={styles.pageHeaderWrapper}>
                        <PageHeader
                            title={t("managmentPanel.pageTitle")}
                            subTitle={`${t("managmentPanel.organizationName")}: ${user.organization}`}
                            badge={getPlanName(user.plan)}
                        />
                        <CopyToClipboard text={code} textToCopy={user.organization_uuid} />
                        {/* <Button variant="secondary" icon={<Plus />} onClick={handleAddClick}>
                            Dodaj pracownika
                        </Button> */}
                    </div>
                    <SectionHeader title={t("managmentPanel.title")} titleSize="huge" />
                    <div className={styles.leftWrapper}>{/* <Menu /> */}</div>
                    {/* <div className={styles.rightWrapper}>
                        {id === 0 && <ManagmentPanel />}
                        {id === 1 && <UsersList />}
                        {id === 2 && employee && <EmployeeDetails employee={employee} />}
                        {id === 3 && <Matrix isEmployeeMatrix={true} />}
                    </div> */}
                    {loading ? (
                        <CircularProgress data-testid="circularProgress" />
                    ) : id === CURRENT_TAB_ID.MATRIX ? (
                        <Matrix isEmployeeMatrix={true} />
                    ) : id === CURRENT_TAB_ID.KANBAN ? (
                        <KanbanBoard />
                    ) : (
                        <>
                            <EmployeeTable employees={employees} matrixes={matrixes} />
                            <div className={styles.paginationContainer}>
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    isPrev={isPrev}
                                    isNext={isNext}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </div>
                <AssignUserToModelModal />
            </Layout>
        </>
    );
};
