import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Competence, ManagmentPanelSingleUserData } from "../../api/types";

export interface ModalState {
    isOpen: boolean;
    item: Competence;
    assignUserModalOpen: {
        isOpen: boolean;
        user: ManagmentPanelSingleUserData;
    };
}

export const initialState: ModalState = {
    isOpen: false,
    assignUserModalOpen: {
        isOpen: false,
        user: {} as ManagmentPanelSingleUserData,
    },
    item: { column_index: -999, level: -999, row_index: -999 },
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        toggleModal: (state, action) => {
            state.item = action.payload;
            state.isOpen = !state.isOpen;
        },
        toggleAssignUserModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                user: ManagmentPanelSingleUserData;
            }>,
        ) => {
            state.assignUserModalOpen = action.payload;
        },
        setText: (state, action) => {
            state.item.description = action.payload;
        },
    },
});

export const { toggleModal, setText, toggleAssignUserModal } = modalSlice.actions;
export default modalSlice.reducer;
