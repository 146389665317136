import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../store/store";

import { buyPlanAsync } from "../../store/Auth_User/userSlice";

import { BlockProps } from "../Block/Block.types";

import { Block } from "../Block/Block";

import styles from "./Payments.module.scss";

export const Payments = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const { user } = useSelector((state: RootState) => state.userStore);

    const payments: BlockProps[] = [
        {
            header: {
                text: "Free plan",
            },
            title: {
                first: "Free",
                second: t("paymentPage.forever"),
            },
            subTitle: "Best for personal use",
            button: {
                text: user.plan === 0 ? t("paymentPage.subscribe") : t("paymentPage.select"),
                action: () => {
                    dispatch(buyPlanAsync(0));
                },
            },
            attributes: [
                t("paymentPage.plans.first.one"),
                t("paymentPage.plans.first.two"),
                t("paymentPage.plans.first.three"),
            ],
            current: user.plan === 0,
        },
        {
            header: {
                text: "Professional plan",
            },
            title: {
                first: "99 zł",
                second: t("paymentPage.monthly"),
            },
            subTitle: "Best for small teams",
            button: {
                text: user.plan === 10 ? t("paymentPage.subscribe") : t("paymentPage.select"),
                action: () => {
                    dispatch(buyPlanAsync(10));
                },
            },
            attributes: [
                t("paymentPage.plans.second.one"),
                t("paymentPage.plans.second.two"),
                t("paymentPage.plans.second.three"),
            ],
            current: user.plan === 10,
        },

        {
            header: {
                text: "Enterprise plan",
            },
            title: {
                first: "999 zł",
                second: t("paymentPage.monthly"),
            },
            subTitle: "Best for many large teams",
            button: {
                text: user.plan === 20 ? t("paymentPage.subscribe") : t("paymentPage.select"),
                action: () => {
                    dispatch(buyPlanAsync(20));
                },
            },
            attributes: [
                t("paymentPage.plans.third.one"),
                t("paymentPage.plans.third.two"),
                t("paymentPage.plans.third.three"),
            ],
            current: user.plan === 20,
        },
    ];

    return (
        <div className={styles.wrapper}>
            <p className={styles.header}>{t("paymentPage.header")}</p>
            <div className={styles.blocksWrapper}>
                {payments.map((payment, i) => {
                    return (
                        <Block
                            key={i}
                            attributes={payment.attributes}
                            button={payment.button}
                            header={payment.header}
                            subTitle={payment.subTitle}
                            title={payment.title}
                            current={payment.current}
                        />
                    );
                })}
            </div>
        </div>
    );
};
