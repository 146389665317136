// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Column_column__X5lsa {
  border-radius: 8px;
  width: 100%;
}

.Column_cardsWrapper__E13XH {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0 0;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/KanbanBoard/Column/Column.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,UAAA;EACA,iBAAA;EACA,QAAA;AAAF","sourcesContent":["\r\n.column {\r\n  border-radius: 8px;\r\n  width: 100%;\r\n}\r\n\r\n.cardsWrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  list-style-type: none;\r\n  padding: 0;\r\n  margin: 8px 0 0 0;\r\n  gap: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": `Column_column__X5lsa`,
	"cardsWrapper": `Column_cardsWrapper__E13XH`
};
export default ___CSS_LOADER_EXPORT___;
