import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { Login } from "../../api/requests/requests";
import { LoginCredentials, Tokens } from "../../api/types";

import { RootState } from "../store";
import { showNotification } from "../Notification/notificationSlice";

export interface AuthState {
    user: Tokens;
    isLoggingIn: boolean;
    error?: SerializedError;
}

export const initialState: AuthState = {
    user: JSON.parse(localStorage.getItem("user") ?? "{}"),
    isLoggingIn: false,
    error: undefined,
};

export const loginAsync = createAsyncThunk("auth/login", async (credentials: LoginCredentials, { dispatch }) => {
    try {
        const response = await Login(credentials);
        dispatch(
            showNotification({
                variant: "success",
                title: "Sukces!",
                subtitle: "Zalogowano pomyślnie.",
            }),
        );
        return response;
    } catch (error) {
        let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Sukces!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearData: (state) => {
            state.user = {} as Tokens;
            localStorage.removeItem("user");
        },
        setTokens: (state, action: PayloadAction<Tokens>) => {
            state.user = action.payload;
            localStorage.setItem("user", JSON.stringify(action.payload));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.isLoggingIn = true;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.isLoggingIn = false;
                state.user = action.payload;

                localStorage.setItem("user", JSON.stringify(action.payload));
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.isLoggingIn = false;
                state.error = action.error;
            });
    },
});

export const { clearData, setTokens } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.user.access;

export const selectRefreshToken = (state: RootState) => state.auth.user.refresh;

export default authSlice.reducer;
