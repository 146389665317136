import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Employee } from "../../api/types";

export interface currentTabIdState {
    id: number;
    employee?: Employee;
    loading?: boolean;
    error?: string;
}

export const initialState: currentTabIdState = {
    id: 0,
    loading: false,
};

const idSlice = createSlice({
    name: "currentTab",
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        setEmployee: (state, action: PayloadAction<Employee | undefined>) => {
            state.employee = action.payload;
        },
    },
});

export const { setId, setEmployee } = idSlice.actions;
export default idSlice.reducer;
