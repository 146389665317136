import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { addMatrix, getAiCompetences, getMatrixJobLevels } from "../../api/requests/requests";

import { showNotification } from "../Notification/notificationSlice";
import { Attitude, CompetencesArea, MatrixJobLevelConfiguration, Organization } from "../../api/types";
import { FormValues } from "../../components/CreateMatrix/CreateMatrix.types";

export const createMatrix = createAsyncThunk("createMatrix/addMatrix", async (data: FormValues, { dispatch }) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("language", data.lang);
    formData.append("configuration", data.jobsLevel.toString());
    if (data.files && data.files?.length > 0) {
        data.files.forEach((file) => formData.append("file", file));
    }
    if (data.description && data.description.trim() !== "") {
        formData.append("description", data.description);
    }
    if (data.links && data.links?.length > 0) {
        data.links.forEach((link) => formData.append("description_links", link));
    }

    try {
        const res = await addMatrix(formData);
        dispatch(
            showNotification({
                variant: "success",
                title: "Sukces!",
                subtitle: "Trwa generowanie modeli.",
            }),
        );
        return res;
    } catch (error) {
        let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

export const generateMatrix = createAsyncThunk("createMatrix/generateMatrix", async (id: number, { dispatch }) => {
    try {
        const res = await getAiCompetences(id);
        dispatch(
            showNotification({
                variant: "success",
                title: "Sukces!",
                subtitle:
                    "Proces generowania przez system listy kompetencji na wybrane przez Ciebie stanowisko zajmie około 5 minut. ",
            }),
        );
        return res;
    } catch (error) {
        let errorMsg = "Wystąpił błąd, spróbuj ponownie później.";
        if (isAxiosError(error) && error?.response?.data.detail) {
            errorMsg = error.response.data.detail;
        }
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: errorMsg,
            }),
        );
        console.log(error);
        throw error;
    }
});

export const geConfigurationJobLevels = createAsyncThunk("createMatrix/geConfigurationJobLevels", async () => {
    const response = await getMatrixJobLevels();
    return response;
});

export interface MatrixDataState {
    data: Organization;
    status: number | null;
    jobLevels: MatrixJobLevelConfiguration[];
    levelsLoading: boolean;
    loading: boolean;
    step: number;
    areas?: CompetencesArea[];
    badges?: Attitude[];
    hasWebsocketError?: boolean;
    error?: string;
}

export const initialState: MatrixDataState = {
    data: {} as Organization,
    step: 0,
    jobLevels: [],
    levelsLoading: false,
    status: null,
    loading: false,
};

const createMatrixSlice = createSlice({
    name: "createMatrix",
    initialState,
    reducers: {
        clearMatrix: (state) => {
            state.data = {} as Organization;
            state.step = 0;
            state.status = null;
        },
        setCreateMatixLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setCreateMatixId: (state, action: PayloadAction<number>) => {
            state.data.id = action.payload;
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMatrix.pending, (state) => {
                state.loading = true;
            })
            .addCase(createMatrix.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(createMatrix.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(generateMatrix.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateMatrix.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload.status;
            })
            .addCase(generateMatrix.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(geConfigurationJobLevels.pending, (state) => {
                state.levelsLoading = true;
            })
            .addCase(geConfigurationJobLevels.fulfilled, (state, action) => {
                state.levelsLoading = false;
                state.jobLevels = action.payload;
            })
            .addCase(geConfigurationJobLevels.rejected, (state, action) => {
                state.levelsLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearMatrix, setCreateMatixLoading, setStep, setCreateMatixId } = createMatrixSlice.actions;
export default createMatrixSlice.reducer;
