import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { resetPassword, resetPasswordConfirm } from "../../api/requests/requests";
import { showNotification } from "../Notification/notificationSlice";

export const passwordReset = createAsyncThunk("lostAccount/passwordReset", async (email: string, { dispatch }) => {
    try {
        const res = await resetPassword(email);
        dispatch(
            showNotification({
                variant: "success",
                title: "Success!",
                subtitle: res.message,
            }),
        );
    } catch (error) {
        const errorMsg = "Wystąpił błąd. Spróbuj później.";
        if (isAxiosError(error)) {
            if (error?.response?.data.detail) {
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        subtitle: error?.response?.data.detail,
                    }),
                );
            } else {
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        subtitle: Object.values(error?.response?.data)[0] as string,
                    }),
                );
            }
        } else {
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
        }

        console.log(error);
        throw error;
    }
});

export const passwordResetConfirm = createAsyncThunk(
    "lostAccount/passwordResetConfirm",
    async (args: { password: string; hash: string }, { dispatch }) => {
        try {
            await resetPasswordConfirm(args.password, args.hash);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Success!",
                    subtitle: "Zmiana przebiegła pomyślnie.",
                }),
            );
        } catch (e) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export interface UserState {
    loading: boolean;
    error?: string;
}

export const initialState: UserState = {
    loading: false,
};

const lostAccountSlice = createSlice({
    name: "lostAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(passwordReset.pending, (state) => {
                state.loading = true;
            })
            .addCase(passwordReset.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(passwordReset.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(passwordResetConfirm.pending, (state) => {
                state.loading = true;
            })
            .addCase(passwordResetConfirm.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(passwordResetConfirm.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default lostAccountSlice.reducer;
