import React, { FC, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";
import { RegisterForm } from "../../components/RegisterForm/RegisterForm";
import Hands from "../../assets/pngs/hands.png";
import { ReactComponent as LogoWhite } from "../../assets/icons/logoWhite.svg";

import styles from "./RegisterPage.module.scss";
export const RegisterPage: FC = () => {
    const { t } = useTranslation();

    useEffect(() => {
        localStorage.removeItem("user");
    }, []);

    return (
        <Layout skipPadding>
            <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                    <img src={Hands} className={styles.logo} alt="outcomesskills" />
                    <a href={process.env.REACT_APP_LANDING_PAGE} className={styles.leftLogoWrapper}>
                        <LogoWhite />
                    </a>
                    <div className={styles.leftFooterWrapper}>
                        <p className={styles.footerText}>2024 © PluckyRebels</p>
                        <RouterLink
                            target="_blank"
                            to="https://outcomeskills.com/polityka-prywatnosci/"
                            rel="noreferrer"
                            className={styles.footerText2}
                        >
                            <p>{t("globals.privacyPolicy")}</p>
                        </RouterLink>
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <RegisterForm />
                </div>
            </div>
        </Layout>
    );
};
