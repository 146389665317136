import React, { FC } from "react";

import classNames from "classnames";

import { Icon } from "../Icon/Icon";

import { IconSize } from "../Icon/Icon.types";

import { ButtonProps } from "./Button.types";
import styles from "./Button.module.scss";
const cx = classNames.bind(styles);

export const Button: FC<ButtonProps> = ({
    children,
    size = "medium",
    href,
    disabled,
    variant = "primary",
    type = "button",
    fullWidth,
    icon,
    iconPosition = "left",
    ...props
}) => {
    const renderIcon = () => {
        return (
            <span className={cx(styles[`${iconPosition}Icon`])}>
                <Icon size={IconSize[size]}>{icon}</Icon>
            </span>
        );
    };

    return href ? (
        <a
            className={cx(styles[variant], styles[size], fullWidth ? styles.fullWidth : undefined)}
            aria-disabled={disabled}
            href={href}
            {...props}
        >
            {((icon && iconPosition === "left") || iconPosition === "singleStand") && renderIcon()}
            {children}
            {icon && iconPosition === "right" && renderIcon()}
        </a>
    ) : (
        <button
            className={cx(styles.button, styles[size], styles[variant], fullWidth ? styles.fullWidth : undefined)}
            aria-disabled={disabled}
            disabled={disabled}
            type={type}
            {...props}
        >
            {((icon && iconPosition === "left") || iconPosition === "singleStand") && renderIcon()}
            {children}
            {icon && iconPosition === "right" && renderIcon()}
        </button>
    );
};
