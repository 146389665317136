import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import {
    assignUserToMatrix,
    deleteUser,
    fetchEmployeesManagmentPanel,
    unassignUserToMatrix,
} from "../../api/requests/requests";
import { ManagmentPanelSingleUserData } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";

export interface EmployeesManagmentPanelState {
    employees: ManagmentPanelSingleUserData[];
    isPrev: boolean;
    isNext: boolean;
    currentPage: number;
    totalPages: number;
    loading: boolean;
    currentEmployeeId?: number;
    error?: string;
}

export const fetchManagmentEmployees = createAsyncThunk("managmentPanel/fetchEmployees", async (page?: number) => {
    const response = await fetchEmployeesManagmentPanel(page);
    return response;
});

export const removeEmployee = createAsyncThunk(
    "managmentPanel/removeEmployee",
    async (userId: number, { dispatch }) => {
        try {
            const res = await deleteUser(userId);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Pracownik został usunięty.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export const unassignEmployee = createAsyncThunk(
    "employeesDatas/unassignEmployees",
    async (args: { matrixId: number; userEmail: string }, { dispatch }) => {
        try {
            const res = await unassignUserToMatrix(args.matrixId, args.userEmail);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Przypisany model został usunięty.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export const assignEmployee = createAsyncThunk(
    "employeesDatas/assignEmployee",
    async (args: { matrixId: number; userEmail: string; matrixName?: string }, { dispatch }) => {
        try {
            const res = await assignUserToMatrix(args.matrixId, args.userEmail);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Udało się przypisać model.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

const updateEmployeesAssignmentStatus = (
    employees: ManagmentPanelSingleUserData[],
    emails: string[],
    type: "assing" | "unassign",
    matrixId: number,
    matrixName: string,
): ManagmentPanelSingleUserData[] => {
    return employees.map((employee) => {
        if (emails.includes(employee.email)) {
            return {
                ...employee,
                assign_matrix_list:
                    type === "unassign"
                        ? employee.assign_matrix_list.filter((matrix) => matrix.pk !== matrixId)
                        : [...employee.assign_matrix_list, { pk: matrixId, name: matrixName }],
            };
        }
        return employee;
    });
};

const initialState: EmployeesManagmentPanelState = {
    employees: [],
    isPrev: false,
    isNext: false,
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: undefined,
};

const managmentPanelSlice = createSlice({
    name: "pagination",
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setCurrentEmployeeId: (state, action: PayloadAction<number | undefined>) => {
            state.currentEmployeeId = action.payload;
        },
        clearError: (state) => {
            state.error = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManagmentEmployees.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchManagmentEmployees.fulfilled, (state, action) => {
                state.loading = false;
                state.employees = action.payload.users.results;
                state.isPrev = action.payload.users.prev;
                state.isPrev = action.payload.users.next;
                state.currentPage = action.payload.users.current_page;
                state.totalPages = action.payload.users.total_pages;
            })
            .addCase(fetchManagmentEmployees.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(removeEmployee.pending, (state, action) => {
                const pk = action.meta.arg;
                state.employees = state.employees.map((employee) =>
                    employee.pk === pk ? { ...employee, isEmployeeAction: true } : employee,
                );
            })
            .addCase(removeEmployee.fulfilled, (state, action) => {
                const pk = action.meta.arg;
                state.employees = state.employees.filter((employee) => employee.pk !== pk);
            })
            .addCase(removeEmployee.rejected, (state, action) => {
                const pk = action.meta.arg;
                state.employees = state.employees.map((employee) =>
                    employee.pk === pk ? { ...employee, isEmployeeAction: false } : employee,
                );
                state.error = action.error.message;
            })
            .addCase(unassignEmployee.pending, (state, action) => {
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: true } : employee,
                );
            })
            .addCase(unassignEmployee.fulfilled, (state, action) => {
                state.employees = updateEmployeesAssignmentStatus(
                    state.employees,
                    [action.meta.arg.userEmail],
                    "unassign",
                    action.meta.arg.matrixId,
                    "",
                );
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: false } : employee,
                );
            })
            .addCase(unassignEmployee.rejected, (state, action) => {
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: false } : employee,
                );
                state.error = action.error.message;
            })
            .addCase(assignEmployee.pending, (state, action) => {
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: true } : employee,
                );
            })
            .addCase(assignEmployee.fulfilled, (state, action) => {
                state.employees = updateEmployeesAssignmentStatus(
                    state.employees,
                    [action.meta.arg.userEmail],
                    "assing",
                    action.meta.arg.matrixId,
                    action.meta.arg.matrixName ?? "",
                );
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: false } : employee,
                );
            })
            .addCase(assignEmployee.rejected, (state, action) => {
                const email = action.meta.arg.userEmail;
                state.employees = state.employees.map((employee) =>
                    employee.email === email ? { ...employee, isEmployeeAction: false } : employee,
                );
                state.error = action.error.message;
            });
    },
});

export const { setPage, clearError, setCurrentEmployeeId } = managmentPanelSlice.actions;
export default managmentPanelSlice.reducer;
