import { Competence, Level } from "../api/types";

export interface GeneratedLevelData {
    name: string;
    competences: Competence[];
    count_all_competence: number;
    count_user_competence: number;
}

export const generateLevelsData = (levels: Level[]): GeneratedLevelData[] => {
    return levels.flatMap((level) => {
        return level.competences.map((competence, j) => {
            return {
                name: `${level.name} ${j + 1}`,
                competences: competence,
                count_all_competence: level.count_all_competence,
                count_user_competence: level.count_user_competence,
            };
        });
    });
};

const generateMockCompetences = (num: number): Competence[] => {
    const mockCompetences: Competence[] = [];
    for (let i = 0; i < num; i++) {
        mockCompetences.push({
            column_index: i % 5,
            row_index: Math.floor(i / 5),
            level: Math.floor(Math.random() * 5) + 1,
            pk: i + 1,
            description: "logo",
        });
    }
    return mockCompetences;
};

export function mergeCompetencesInLevels(levels: Level[]): Level[] {
    return levels.flatMap((level) => {
        const filteredCompetences = level.competences.flat().filter((competence: Competence) => !competence.is_empty);
        const chunkSize = 12;
        const chunks: Competence[][] = [];
        for (let i = 0; i < filteredCompetences.length; i += chunkSize) {
            chunks.push(filteredCompetences.slice(i, i + chunkSize));
        }
        const result: Level[] = [];
        chunks.forEach((competencesChunk) => {
            const emptyCompetences: Competence[] = generateMockCompetences(competencesChunk.length);
            result.push({ ...level, competences: [competencesChunk] });
            result.push({ ...level, competences: [emptyCompetences] });
        });
        return result;
    });
}
