// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmployeeTable_tableCell__1Y0IV {
  font-size: 12px;
  font-weight: 500;
}

.EmployeeTable_namesContainer__uHHmO {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.EmployeeTable_role__Yli4W {
  font-weight: 400;
}

.EmployeeTable_modelsContainer__D7kKG {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/EmployeeTable/EmployeeTable.module.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;AADJ","sourcesContent":["@use '../../styles/colors.scss' as colors;\r\n\r\n.tableCell {\r\n    font-size: 12px;\r\n    font-weight: 500;\r\n}\r\n\r\n.namesContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n}\r\n\r\n.role {\r\n    font-weight: 400;\r\n}\r\n\r\n.modelsContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    gap: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": `EmployeeTable_tableCell__1Y0IV`,
	"namesContainer": `EmployeeTable_namesContainer__uHHmO`,
	"role": `EmployeeTable_role__Yli4W`,
	"modelsContainer": `EmployeeTable_modelsContainer__D7kKG`
};
export default ___CSS_LOADER_EXPORT___;
