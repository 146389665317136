import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import {
    assignUserToMatrix,
    deleteCompetence,
    duplicateCompetence,
    editCompetence,
    editCompetenceInfoField,
    editCompetencePosition,
    getCompetencesTable,
    getEmployeeCompetencesTable,
    learnCompetence,
    markCompetence,
    postCompetence,
    unassignUserToMatrix,
} from "../../api/requests/requests";
import { Competence, CompetenceBasic, EmployeeData, Level, MovingCompetenceResponse, Table } from "../../api/types";

import { showNotification } from "../Notification/notificationSlice";
import { handleError } from "../../utils/handleError";

interface MoveCompetenceArgs {
    id: number;
    matrixId: number;
    row_index?: number;
    column_index?: number;
    level?: number;
    id_taken_spot?: number;
    is_last_one?: boolean;
}

interface duplicateCompetenceArgs {
    id: number;
    matrixId: number;
}

export const fetchLevels = createAsyncThunk("competences/fetchLevels", async (id: number) => {
    const response = await getCompetencesTable(id);
    return response;
});

export const fetchEmployeeLevels = createAsyncThunk(
    "currentTab/fetchEmployeeLevels",
    async (values: { id: number; userId: number }) => {
        const response = await getEmployeeCompetencesTable(values.id, values.userId);
        return response;
    },
);

export const editCompetenceDescription = createAsyncThunk(
    "competences/editCompetenceDescription",
    async (args: { matrixId: number; item: Competence }, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const { matrixId, item } = args;
        if (item.pk && item.description) {
            try {
                const res = await editCompetence(matrixId, item);
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: "Edycja kompetencji zakończona powodzeniem.",
                    }),
                );
                return res;
            } catch (e) {
                let errorMsg = "Wystąpił błąd przy próbie edycji. Spróbuj później.";
                if (isAxiosError(e) && e?.response?.data.detail) {
                    errorMsg = e.response.data.detail;
                }
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        subtitle: errorMsg,
                    }),
                );
                console.log(e);
                throw e;
            }
        }
    },
);

export const editCompetenceInfo = createAsyncThunk(
    "competences/editCompetenceInfo",
    async (args: { matrixId: number; item: Competence }, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const { matrixId, item } = args;
        if (item.pk && item.additional_info) {
            try {
                const res = await editCompetenceInfoField(matrixId, item.pk, item.additional_info);
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: "Edycja kompetencji zakończona powodzeniem.",
                    }),
                );
                return res;
            } catch (e) {
                let errorMsg = "Wystąpił błąd przy próbie edycji. Spróbuj później.";
                if (isAxiosError(e) && e?.response?.data.detail) {
                    errorMsg = e.response.data.detail;
                }
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        subtitle: errorMsg,
                    }),
                );
                console.log(e);
                throw e;
            }
        }
    },
);

export const moveSingleCompetence = createAsyncThunk(
    "competences/moveSingleCompetence",
    async (
        { matrixId, id, row_index, column_index, id_taken_spot, level, is_last_one }: MoveCompetenceArgs,
        { dispatch },
    ) => {
        try {
            const res = await editCompetencePosition(matrixId, {
                id: id,
                level: level,
                row_index: row_index,
                column_index: column_index,
                id_taken_spot: id_taken_spot,
            });
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Edycja kompetencji zakończona powodzeniem.",
                }),
            );
            if (is_last_one) {
                return await getCompetencesTable(matrixId);
            } else {
                return res;
            }
        } catch (e) {
            let errorMsg = "Wystąpił błąd przy próbie edycji. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export const markSingleCompetence = createAsyncThunk(
    "competences/markSingleCompetence",
    async (args: { matrixId: number; itemPk: number; learning_requirement_status: number }, { dispatch }) => {
        try {
            const res = await markCompetence(args.matrixId, args.itemPk, args.learning_requirement_status);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Operacja zakończona powodzeniem.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd podczas próby zaznaczania. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export const learnSingleCompetence = createAsyncThunk(
    "competences/learnSingleCompetence",
    async (args: { matrixId: number; itemPk: number; learning_status: boolean }, { dispatch }) => {
        try {
            const res = await learnCompetence(args.matrixId, args.itemPk, args.learning_status);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Operacja zakończona powodzeniem.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd podczas próby zaznaczania. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            throw e;
        }
    },
);

export const deleteSingleCompetence = createAsyncThunk(
    "competences/deleteCompetence",
    async (args: { matrixId: number; item: Competence }, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const { matrixId, item } = args;
        if (item.pk) {
            try {
                const res = await deleteCompetence(matrixId, item.pk);
                dispatch(
                    showNotification({
                        variant: "success",
                        title: "Sukces!",
                        subtitle: "Kompetencja została usunięta.",
                    }),
                );
                return res;
            } catch (e) {
                let errorMsg = "Wystąpił błąd podczas próby usunięcia. Spróbuj później.";
                if (isAxiosError(e) && e?.response?.data.detail) {
                    errorMsg = e.response.data.detail;
                }
                dispatch(
                    showNotification({
                        variant: "error",
                        title: "Error!",
                        subtitle: errorMsg,
                    }),
                );
                console.log(e);
                throw e;
            }
        }
    },
);

export const duplicateSingleCompetence = createAsyncThunk(
    "competences/duplicateSingleCompetence",
    async ({ matrixId, id }: duplicateCompetenceArgs, { dispatch }) => {
        try {
            await duplicateCompetence(matrixId, id);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Duplikacja kompetencji zakończona powodzeniem.",
                }),
            );
            return await getCompetencesTable(matrixId);
        } catch (e) {
            let errorMsg = "Wystąpił błąd podczas próby duplikacji. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            throw e;
        }
    },
);

export const createSingleCompetence = createAsyncThunk(
    "competences/createSingleCompetence",
    async (args: { matrixId: number; item: Competence; is_last_one?: boolean }, thunkAPI) => {
        const { dispatch } = thunkAPI;
        const { matrixId, item, is_last_one } = args;
        try {
            const res = await postCompetence(matrixId, item);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Kompetencja została dodana.",
                }),
            );
            if (is_last_one) {
                return await getCompetencesTable(matrixId);
            } else {
                return res;
            }
        } catch (e) {
            let errorMsg = "Wystąpił błąd podczas próby dodania. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export interface MatrixNewState {
    levels: Level[];
    loading: boolean;
    isEditing: boolean;
    isMarking: boolean;
    isAssigning: boolean;
    employees: EmployeeData[];
    skipSublevels?: boolean;
    error?: string;
}

export const initialState: MatrixNewState = {
    levels: [],
    loading: false,
    isEditing: false,
    isMarking: false,
    isAssigning: false,
    employees: [],
    error: "",
};

const findTileIndexes = (
    levels: Level[],
    pk: number,
): { levelIndex: number; rowIndex: number; tileIndex: number } | undefined => {
    for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
        const level = levels[levelIndex];

        for (let rowIndex = 0; rowIndex < level.competences.length; rowIndex++) {
            const tileIndex = level.competences[rowIndex].findIndex((tile) => tile.pk === pk);

            if (tileIndex !== -1) {
                return { levelIndex, rowIndex, tileIndex };
            }
        }
    }

    return undefined;
};

const removeTileFromLevels = (levels: Level[], pk: number): Level[] => {
    return levels.map((level) => ({
        ...level,
        competences: level.competences.map((row) => row.filter((tile) => tile.pk !== pk)),
    }));
};

const findCompetenceByCoordinates = (
    levels: Level[],
    coordinates: CompetenceBasic,
): { levelIndex: number; rowIndex: number; tileIndex: number } | undefined => {
    for (let levelIndex = 0; levelIndex < levels.length; levelIndex++) {
        const level = levels[levelIndex];

        for (let rowIndex = 0; rowIndex < level.competences.length; rowIndex++) {
            const tileIndex = level.competences[rowIndex].findIndex(
                (tile) =>
                    tile.column_index === coordinates.column_index &&
                    tile.row_index === coordinates.row_index &&
                    tile.level === coordinates.level,
            );

            if (tileIndex !== -1) {
                return { levelIndex, rowIndex, tileIndex };
            }
        }
    }

    return undefined;
};

const updateEmployeesAssignmentStatus = (
    employees: EmployeeData[],
    emails: string[],
    type: "assing" | "unassign",
): EmployeeData[] => {
    return employees.map((employee) => {
        if (emails.includes(employee.email)) {
            return { ...employee, is_assigned_current_matrix: type === "assing" ? true : false };
        }
        return employee;
    });
};

export const assignEmployees = createAsyncThunk(
    "employeesDatas/assignEmployees",
    async (args: { matrixId: number; userEmail: string }, { dispatch }) => {
        try {
            const res = await assignUserToMatrix(args.matrixId, args.userEmail);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Pracownik został dodany.",
                }),
            );
            return res;
        } catch (e) {
            let errorMsg = "Wystąpił błąd. Spróbuj później.";
            if (isAxiosError(e) && e?.response?.data.detail) {
                errorMsg = e.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(e);
            throw e;
        }
    },
);

export const unassignEmployees = createAsyncThunk(
    "employeesDatas/unassignEmployees",
    async (args: { matrixId: number; userEmail: string }, { dispatch }) => {
        try {
            const res = await unassignUserToMatrix(args.matrixId, args.userEmail);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Pracownik został usunięty.",
                }),
            );
            return res;
        } catch (e) {
            handleError(e, dispatch);
        }
    },
);

const competencesSlice = createSlice({
    name: "competences",
    initialState,
    reducers: {
        clearCompetences: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLevels.pending, (state) => {
                state.loading = true;
                state.isAssigning = true;
            })
            .addCase(fetchLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.isAssigning = false;
                state.levels = action.payload.competences_list;
                state.employees = action.payload.users;
                state.skipSublevels = action.payload.skip_sublevels;
            })
            .addCase(fetchLevels.rejected, (state, action) => {
                state.loading = false;
                state.isAssigning = false;
                state.error = action.error.message;
            })
            .addCase(fetchEmployeeLevels.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEmployeeLevels.fulfilled, (state, action) => {
                state.loading = false;
                state.levels = action.payload;
            })
            .addCase(fetchEmployeeLevels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(editCompetenceInfo.pending, (state, action) => {
                const { item } = action.meta.arg;
                if (item?.pk) {
                    const indexes = findTileIndexes(state.levels, item.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
            })
            .addCase(editCompetenceInfo.fulfilled, (state, action) => {
                if (action.payload?.pk) {
                    const indexes = findTileIndexes(state.levels, action.payload?.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex] = {
                            ...action.payload,
                            is_editing: false,
                        };
                    }
                }
            })
            .addCase(editCompetenceInfo.rejected, (state, action) => {
                const { item } = action.meta.arg;
                if (item?.pk) {
                    const indexes = findTileIndexes(state.levels, item.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.error = action.error.message;
            })
            .addCase(editCompetenceDescription.pending, (state, action) => {
                const { item } = action.meta.arg;
                if (item?.pk) {
                    const indexes = findTileIndexes(state.levels, item.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
            })
            .addCase(editCompetenceDescription.fulfilled, (state, action) => {
                if (action.payload?.pk) {
                    const indexes = findTileIndexes(state.levels, action.payload?.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex] = {
                            ...action.payload,
                            is_editing: false,
                        };
                    }
                }
            })
            .addCase(editCompetenceDescription.rejected, (state, action) => {
                const { item } = action.meta.arg;
                if (item?.pk) {
                    const indexes = findTileIndexes(state.levels, item.pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.error = action.error.message;
            })
            .addCase(moveSingleCompetence.pending, (state, action) => {
                const { id } = action.meta.arg;
                const { is_last_one } = action.meta.arg;
                if (is_last_one) {
                    state.loading = true;
                } else if (id) {
                    state.isEditing = true;
                    const indexes = findTileIndexes(state.levels, id);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
            })
            .addCase(moveSingleCompetence.fulfilled, (state, action) => {
                const actionPayloadTyped = action.payload as MovingCompetenceResponse;
                const { is_last_one } = action.meta.arg;
                if (is_last_one) {
                    const actionPayloadTyped = action.payload as Table;
                    state.loading = false;
                    state.levels = actionPayloadTyped.competences_list as Level[];
                } else if (actionPayloadTyped.source_competence.pk) {
                    state.isEditing = false;
                    const source_indexes = findTileIndexes(state.levels, actionPayloadTyped.source_competence.pk);
                    if (source_indexes) {
                        state.levels[source_indexes.levelIndex].competences[source_indexes.rowIndex][
                            source_indexes.tileIndex
                        ] = actionPayloadTyped.target_competence;
                    }
                    const foundCompetence = findCompetenceByCoordinates(
                        state.levels,
                        actionPayloadTyped.source_competence,
                    );
                    if (foundCompetence) {
                        const { levelIndex, rowIndex, tileIndex } = foundCompetence;
                        state.levels[levelIndex].competences[rowIndex][tileIndex] =
                            actionPayloadTyped.source_competence;
                    }
                }
            })
            .addCase(moveSingleCompetence.rejected, (state, action) => {
                const { id } = action.meta.arg;
                const { is_last_one } = action.meta.arg;
                if (is_last_one) {
                    state.loading = false;
                } else if (id) {
                    const indexes = findTileIndexes(state.levels, id);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.isEditing = false;
                state.error = action.error.message;
            })
            .addCase(markSingleCompetence.pending, (state, action) => {
                const itemPk = action.meta.arg.itemPk;
                if (itemPk) {
                    const indexes = findTileIndexes(state.levels, itemPk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
                state.isMarking = true;
            })
            .addCase(markSingleCompetence.fulfilled, (state, action) => {
                const indexes = findTileIndexes(state.levels, action.payload?.pk);
                if (indexes) {
                    state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex] = {
                        ...state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex],
                        is_editing: false,
                        learning_requirement_status: action.meta.arg.learning_requirement_status, // TODO IN FUTURE TO payload,
                    };
                }
                state.isMarking = false;
            })
            .addCase(markSingleCompetence.rejected, (state, action) => {
                const itemPk = action.meta.arg.itemPk;
                if (itemPk) {
                    const indexes = findTileIndexes(state.levels, itemPk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.error = action.error.message;
                state.isMarking = false;
            })
            .addCase(learnSingleCompetence.pending, (state, action) => {
                const itemPk = action.meta.arg.itemPk;
                if (itemPk) {
                    const indexes = findTileIndexes(state.levels, itemPk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
                state.isMarking = true;
            })
            .addCase(learnSingleCompetence.fulfilled, (state, action) => {
                const indexes = findTileIndexes(state.levels, action.payload?.pk);
                if (indexes) {
                    if (
                        action.payload.learning_status !==
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex]
                            .learning_status
                    ) {
                        const shouldIncrement = action.payload.learning_status === true;
                        state.levels[indexes.levelIndex].count_user_competence += shouldIncrement ? 1 : -1;
                    }
                    state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex] = {
                        ...state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex],
                        is_editing: false,
                        learning_status: action.payload.learning_status,
                    };
                }
                state.isMarking = false;
            })
            .addCase(learnSingleCompetence.rejected, (state, action) => {
                const itemPk = action.meta.arg.itemPk;
                if (itemPk) {
                    const indexes = findTileIndexes(state.levels, itemPk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.error = action.error.message;
                state.isMarking = false;
            })
            .addCase(deleteSingleCompetence.pending, (state, action) => {
                const { pk } = action.meta.arg.item;
                if (pk) {
                    const indexes = findTileIndexes(state.levels, pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
            })
            .addCase(deleteSingleCompetence.fulfilled, (state, action) => {
                const { pk } = action.meta.arg.item;
                if (pk) {
                    state.levels = removeTileFromLevels(state.levels, pk);
                }
            })
            .addCase(deleteSingleCompetence.rejected, (state, action) => {
                const { pk } = action.meta.arg.item;
                if (pk) {
                    const indexes = findTileIndexes(state.levels, pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex] = {
                            ...state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex],
                            is_editing: false,
                        };
                    }
                }
                state.error = action.error.message;
            })
            .addCase(duplicateSingleCompetence.pending, (state) => {
                state.loading = true;
            })
            .addCase(duplicateSingleCompetence.fulfilled, (state, action) => {
                state.levels = action.payload.competences_list as Level[];
                state.loading = false;
            })
            .addCase(duplicateSingleCompetence.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createSingleCompetence.pending, (state, action) => {
                const { is_last_one } = action.meta.arg;
                const { pk } = action.meta.arg.item;

                if (is_last_one) {
                    state.loading = true;
                } else if (pk) {
                    const indexes = findTileIndexes(state.levels, pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            true;
                    }
                }
            })
            .addCase(createSingleCompetence.fulfilled, (state, action) => {
                const { is_last_one } = action.meta.arg;
                if (is_last_one) {
                    const actionPayloadTyped = action.payload as Table;
                    state.levels = actionPayloadTyped.competences_list as Level[];
                    state.loading = false;
                } else {
                    const foundCompetence = findCompetenceByCoordinates(state.levels, action.payload as Competence);
                    if (foundCompetence) {
                        const { levelIndex, rowIndex, tileIndex } = foundCompetence;
                        state.levels[levelIndex].competences[rowIndex][tileIndex] = {
                            ...action.payload,
                            is_editing: false,
                        } as Competence;
                    }
                }
            })
            .addCase(createSingleCompetence.rejected, (state, action) => {
                const { pk } = action.meta.arg.item;
                const { is_last_one } = action.meta.arg;
                if (is_last_one) {
                    state.loading = false;
                } else if (pk) {
                    const indexes = findTileIndexes(state.levels, pk);
                    if (indexes) {
                        state.levels[indexes.levelIndex].competences[indexes.rowIndex][indexes.tileIndex].is_editing =
                            false;
                    }
                }
                state.error = action.error.message;
            })
            .addCase(unassignEmployees.pending, (state) => {
                state.isAssigning = true;
            })
            .addCase(unassignEmployees.fulfilled, (state, action) => {
                state.employees = updateEmployeesAssignmentStatus(
                    state.employees,
                    [action.meta.arg.userEmail],
                    "unassign",
                );
                state.isAssigning = false;
            })
            .addCase(unassignEmployees.rejected, (state, action) => {
                state.isAssigning = false;
                state.error = action.error.message;
            })
            .addCase(assignEmployees.pending, (state) => {
                state.isAssigning = true;
            })
            .addCase(assignEmployees.fulfilled, (state, action) => {
                state.employees = updateEmployeesAssignmentStatus(
                    state.employees,
                    [action.meta.arg.userEmail],
                    "assing",
                );
                state.isAssigning = false;
            })
            .addCase(assignEmployees.rejected, (state, action) => {
                state.isAssigning = false;
                state.error = action.error.message;
            });
    },
});

export const { clearCompetences } = competencesSlice.actions;
export default competencesSlice.reducer;
