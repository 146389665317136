import { useTranslation } from "react-i18next";

import { useState } from "react";

import { Button } from "../../Button/Button";

import { ReactComponent as Plus } from "../../../assets/icons/16x16/plus.svg";

import { AddGoalModal } from "../AddGoalModal/AddGoalModal";

import { CompetenceGoalProps } from "./CompetenceGoal.types";
import styles from "./CompetenceGoal.module.scss";

export const CompetenceGoal = ({ goal, isEmpty, column }: CompetenceGoalProps) => {
    const { t } = useTranslation();
    const [showAddCompetenceGoalModal, setShowAddCompetenceGoalModal] = useState<boolean>(false);
    const handleAdd = () => {
        setShowAddCompetenceGoalModal(!showAddCompetenceGoalModal);
    };

    return (
        <div className={isEmpty ? styles.containerEmpty : styles.container}>
            {goal ? (
                <span className={styles.description}>{goal.description}</span>
            ) : (
                column && (
                    <>
                        <AddGoalModal
                            column={column}
                            isOpen={showAddCompetenceGoalModal}
                            toggleOpen={() => setShowAddCompetenceGoalModal(!showAddCompetenceGoalModal)}
                        />
                        <Button data-testid="addButton" variant="primary" icon={<Plus />} onClick={handleAdd}>
                            {t("kanbanPage.addCompetence")}
                        </Button>
                    </>
                )
            )}
        </div>
    );
};
