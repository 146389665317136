import React, { FC } from "react";

import { ReactComponent as Trash } from "../../assets/icons/16x16/trash.svg";

import { Button } from "../Button/Button";

import { BadgeWithLabelProps } from "./BadgeWithLabel.types";
import styles from "./BadgeWithLabel.module.scss";

export const BadgeWithLabel: FC<BadgeWithLabelProps> = ({ text, label, textAction, handleAction }) => {
    return (
        <p className={styles.wrapper}>
            {/* <span className={styles.label}>{label}</span> */}
            <button className={styles.text} onClick={textAction}>
                {text}
            </button>

            <Button
                onClick={handleAction}
                icon={<Trash data-testid="trashIcon" style={{ color: "#9E77ED" }} />}
                variant="icon"
                size="small"
                iconPosition="singleStand"
            />
        </p>
    );
};
