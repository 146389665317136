import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "../../Button/Button";
import { ReactComponent as Check } from "../../../assets/icons/16x16/x-circle.svg";

import styles from "./Error.module.scss";

export const Error: FC = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.iconContainer}>
                <Check className={styles.icon} />
            </div>
            <p className={styles.subHeader}>Ups...</p>
            <p className={styles.header}>Niedokończona transakcja</p>
            <p className={styles.supportText}>Coś poszło nie tak</p>
            <Button
                fullWidth
                onClick={() => navigate("/payment")}
                variant={"secondary"}
                style={{ fontSize: "16px", height: "48px", fontWeight: 500, maxWidth: "312px", marginTop: "72px" }}
            >
                Spróbuj ponownie
            </Button>
        </div>
    );
};
