import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";

import { useDispatch } from "react-redux";

import { switchLanguage } from "../../store/Language/languageSlice";
import { AppDispatch } from "../../store/store";

const LanguageSwitcher = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [language, setLanguage] = React.useState(i18n.language);

    const handleChange = (event: SelectChangeEvent) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        dispatch(switchLanguage(selectedLanguage));
    };

    return (
        <FormControl
            variant="outlined"
            sx={{
                width: 120,
                "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "#7F56D9",
                    },
                    "&:hover fieldset": {
                        borderColor: "#7F56D9",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#7F56D9",
                    },
                },
            }}
        >
            <InputLabel
                sx={{
                    "&.Mui-focused": {
                        color: "#7F56D9",
                    },
                }}
                id="language-select-label"
            >
                {t("globals.language")}
            </InputLabel>
            <Select
                style={{ maxHeight: "40px" }}
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={handleChange}
                label="Language"
            >
                <MenuItem style={{ paddingTop: "32px" }} value="en">
                    EN
                </MenuItem>
                <MenuItem value="pl">PL</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
