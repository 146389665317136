import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getMatrixCourses } from "../../api/requests/requests";
import { Course } from "../../api/types";

export const fetchCourses = createAsyncThunk("courses/fetchCourses", async (id: number) => {
    const response = await getMatrixCourses(id);
    return response;
});

export interface CoursesState {
    courses: { [key: string]: Course[] };
    loading: boolean;
    error?: string;
}

export const initialState: CoursesState = {
    courses: {},
    loading: false,
    error: undefined,
};

const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload;
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default coursesSlice.reducer;
