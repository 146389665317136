import React, { FC, useEffect } from "react";
import { MenuItem, Popover } from "@mui/material";

import { MenuProps } from "./Menu.types";
import styles from "./Menu.module.scss";

export const Menu: FC<MenuProps> = ({ actions, isOpen, toogleAction, anchorEl }) => {
    const handleScroll = () => {
        if (isOpen && anchorEl) {
            const popoverRect = anchorEl.getBoundingClientRect();
            if (popoverRect.top < 0 || popoverRect.bottom > 0) {
                toogleAction();
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isOpen, anchorEl]);

    return (
        <div>
            <Popover
                disableScrollLock
                anchorEl={anchorEl}
                open={isOpen}
                onClose={toogleAction}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                {actions.map((action, i) => {
                    return (
                        <MenuItem
                            disabled={action.disabled}
                            key={i}
                            onClick={(e) => {
                                action.action(e);
                                toogleAction();
                            }}
                            className={styles.menuItem}
                            data-testid={action.dataTestid}
                            component={"button"}
                        >
                            <span className={styles.iconWrapper}>{action.icon}</span>
                            <span className={styles.nameWrapper}>{action.name}</span>
                        </MenuItem>
                    );
                })}
            </Popover>
        </div>
    );
};
