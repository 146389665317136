import React, { FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/pl";
dayjs.locale("pl");

import { useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../store/store";

import { GoalData, Goal as GoalType } from "../../api/types";

import { createSingleGoal, editSingleGoal } from "../../store/Kanban/Goals/Goals.slice";

import { ReactComponent as Award } from "../../assets/icons/24x24/award.svg";
import { ReactComponent as Calendar } from "../../assets/icons/24x24/calendar-date.svg";
import { ReactComponent as Plus } from "../../assets/icons/20x20/plus.svg";
import { ReactComponent as Edit } from "../../assets/icons/16x16/edit2.svg";

import { SectionHeader } from "../SectionHeader/SectionHeader";

import { Button } from "../Button/Button";

import styles from "./Goal.module.scss";
import { EditGoalModal } from "./EditGoalModal/EditGoalModal";

export const Goal: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const [editedGoal, setEditedGoal] = useState<GoalData | GoalType | undefined>(undefined);
    const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
    const { loading, goals } = useSelector((state: RootState) => state.goals);
    const { id } = useSelector((state: RootState) => state.currentMatrix);
    const { user } = useSelector((state: RootState) => state.userStore);
    const currentDate = dayjs().format("YYYY-MM-DD");

    const goal = goals[0] as GoalType;

    const handleEdit = () => {
        if (!id) return;
        if (goal) {
            setEditedGoal(goal);
        } else {
            setEditedGoal({
                deadline: currentDate,
                development_goal: "",
                matrixPk: id,
                user: user.pk,
            });
        }
        setEditModalOpen((prevState) => !prevState);
    };

    const handleEditGoalConfirm = async () => {
        if (!editedGoal) return;
        if ("pk" in editedGoal) {
            await dispatch(editSingleGoal(editedGoal));
        } else {
            await dispatch(createSingleGoal(editedGoal));
        }
        setEditedGoal(undefined);
        setEditModalOpen(!isEditModalOpen);
    };

    if (loading) {
        return <CircularProgress data-testid="circularProgress" />;
    }

    if (!goal && !editedGoal) {
        return (
            <Button variant="secondary" icon={<Plus />} onClick={handleEdit}>
                {t("kanbanPage.addGoal")}
            </Button>
        );
    }

    if (editedGoal) {
        return (
            <EditGoalModal
                handleSubmit={handleEditGoalConfirm}
                setEditedGoal={setEditedGoal}
                goal={editedGoal}
                isOpen={isEditModalOpen}
                toggleOpen={() => setEditModalOpen(!isEditModalOpen)}
            />
        );
    }

    return (
        <>
            <div className={styles.goalsButtonWrapper}>
                <div className={styles.goalsWrapper}>
                    <SectionHeader
                        isTruncatedSubtitle
                        variant="secondary"
                        title={`${t("kanbanPage.goal")}:`}
                        subTitle={goal.development_goal}
                        icon={<Award />}
                    />
                    <SectionHeader
                        variant="secondary"
                        title={`${t("kanbanPage.deadline")}:`}
                        subTitle={dayjs(goal.deadline).format("DD MMMM YYYY")}
                        icon={<Calendar />}
                    />
                </div>
                <button className={styles.iconButton} onClick={handleEdit} data-testid="edit-button">
                    <Edit />
                </button>
            </div>
        </>
    );
};
