import React, { FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";

import { setText, toggleModal } from "../../store/Info/infoSlice";
import { AppDispatch, RootState } from "../../store/store";

import { Button } from "../Button/Button";
import { editCompetenceInfo } from "../../store/Competences/competencesSlice";

import styles from "./InfoTileModal.module.scss";

const InfoTileModal: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [editMode, setEditMode] = useState<boolean>(false);
    const { isOpen, item } = useSelector((state: RootState) => state.infoModal);
    const { id } = useSelector((state: RootState) => state.currentMatrix);

    const handleEdit = () => {
        setEditMode(!editMode);
    };

    const handleSubmit = () => {
        if (item && id) {
            dispatch(
                editCompetenceInfo({
                    item: item,
                    matrixId: id,
                }),
            );
            setEditMode(!editMode);
            dispatch(toggleModal(""));
        }
    };

    return (
        <Modal
            className={styles.modalContainer}
            open={isOpen}
            onClose={() => {
                setEditMode(false);
                dispatch(toggleModal(""));
            }}
        >
            <div className={styles.modalInnerContainer}>
                {!editMode && handleEdit && (
                    <button type="button" onClick={handleEdit} className={styles.iconButton}>
                        ✏️
                    </button>
                )}
                {editMode ? (
                    <>
                        <textarea
                            className={styles.textarea}
                            value={item?.additional_info}
                            onChange={(e) => dispatch(setText(e.target.value))}
                            rows={4}
                            cols={50}
                        />
                        <Button onClick={handleSubmit}>Aktualizuj</Button>
                    </>
                ) : (
                    <p>{item?.additional_info}</p>
                )}
            </div>
        </Modal>
    );
};

export default InfoTileModal;
