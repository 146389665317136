import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { changeLanguage } from "../../api/requests/requests";

export const switchLanguage = createAsyncThunk("employees/getEmployees", async (lang: string) => {
    //JUST USING BY BACKEND, DO NOT NEED TRY/CATCH FOR NOW
    const response = await changeLanguage(lang);
    return response;
});

export interface LanguageState {
    language: string;
    loading: boolean;
    error?: string;
}

export const initialState: LanguageState = {
    language: "pl",
    loading: false,
    error: undefined,
};

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(switchLanguage.pending, (state) => {
                state.loading = true;
            })
            .addCase(switchLanguage.fulfilled, (state) => {
                state.loading = false;
                // state.language = action.payload;
            })
            .addCase(switchLanguage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default languageSlice.reducer;
