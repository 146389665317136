import React, { FC } from "react";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";

import { RootState } from "../../store/store";

import { Navbar } from "../../components/Navbar/Navbar";
import { PageHeader } from "../../components/PageHeader/PageHeader";

import { ActionButton } from "../../components/Navbar/Navbar.types";

import { Error } from "../../components/Payment/Error/Error";

import styles from "./ErrorPage.module.scss";

export const ErrorPage: FC = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.userStore);
    const { t } = useTranslation();

    const handleBack = () => {
        navigate("/matrixes");
    };

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ];

    return (
        <>
            <Navbar actionButtons={actionButtons} />
            <Layout>
                <div className={styles.wrapper}>
                    <div className={styles.pageHeader}>
                        <PageHeader title={`${t("globals.back")} ${user.first_name} ${user.last_name}`} />
                    </div>
                    <Error />
                </div>
            </Layout>
        </>
    );
};
