import React, { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";

import { Navbar } from "../../components/Navbar/Navbar";

import { ActionButton } from "../../components/Navbar/Navbar.types";

import { RootState } from "../../store/store";

import PrintableMatrixPDFViewer from "../../components/PrintableMatrixPDFViewer/PrintableMatrixPDFViewer";

import styles from "./ExportPage.module.scss";

export const ExportPage: FC = () => {
    const navigate = useNavigate();
    const { id, roles, name } = useSelector((state: RootState) => state.currentMatrix);
    const { levels } = useSelector((state: RootState) => state.competences);

    const { t } = useTranslation();

    useEffect(() => {
        if (!id) {
            navigate("/matrixes");
        }
    }, []);

    const handleBack = () => {
        navigate("/matrixes");
    };

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ].filter((button) => button !== null) as ActionButton[];

    return (
        <>
            <Navbar actionButtons={actionButtons} />
            <Layout>
                <PrintableMatrixPDFViewer
                    fileName={`Kompetencje_${name}_Outcome Skills`}
                    levels={levels}
                    roles={roles}
                />
            </Layout>
        </>
    );
};
