import classNames from "classnames";
import React, { FC } from "react";

import { IconProps } from "./Icon.types";

import styles from "./Icon.module.scss";

const cx = classNames.bind(styles);

export const Icon: FC<IconProps> = ({ children, size, customClass }: IconProps) => {
    return (
        <span aria-hidden className={cx(styles.icon, customClass, size ? styles[size] : undefined)}>
            {children}
        </span>
    );
};
