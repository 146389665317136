import { ReactNode } from "react";

export enum IconSize {
    xsmall = "xsmall",
    small = "small",
    medium = "medium",
    large = "large",
}

export interface IconProps {
    size?: IconSize;
    children: ReactNode;
    customClass?: string;
}
