import React from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";

import { CircularProgress } from "@mui/material";

import { Card } from "../Card/Card";

// import { TitleInput } from "../TitleInput/TitleInput";

import { Column as ColumnType, Competence, CompetenceGoal as CompetenceGoalType } from "../../../api/types";

import { isCompetence } from "../KanbanBoard";

import { CompetenceGoal } from "../CompetenceGoal/CompetenceGoal";

import styles from "./Column.module.scss";
import { ColumnProps } from "./Column.types";
import { Header } from "./Header/Header";

const countMatrixesItems = (column: ColumnType): number => {
    return column.matrixes.reduce((acc, matrix) => {
        return acc + matrix.competences.length;
    }, column.goals.length);
};

export const Column = ({ onColumnChange, areItemsMoving, column }: ColumnProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "card",
        drop: (item: Competence | CompetenceGoalType) => {
            const isNormalCompetence = isCompetence(item);
            onColumnChange(item, isNormalCompetence ? column.matrixes[0].table_column_pk : column.order);
        },
        collect: (monitor: DropTargetMonitor<Competence, unknown>) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const counter = countMatrixesItems(column);
    return (
        <div className={styles.column} ref={drop}>
            <Header title={column.name} counter={counter} />
            <ul className={styles.cardsWrapper}>
                {areItemsMoving ? (
                    <CircularProgress />
                ) : (
                    column.matrixes.map((matrix) => {
                        return matrix.competences.map((item, i) => (
                            <li key={`comp-${item.pk}-${i}`}>
                                <Card item={item} />
                            </li>
                        ));
                    })
                )}
                {column.goals.map((goal, i) => (
                    <Card item={goal} key={`goal-${goal.pk}-${i}`} />
                ))}
                <CompetenceGoal column={column} isEmpty />
            </ul>
            {/* {canDrop && `Zrzut${isOver ? ", tu?" : ""}`} */}
            {/* <div className={styles.newItemWrapper}>
                <TitleInput onSubmit={(title: string) => onNewItem?.(title)} />
            </div> */}
        </div>
    );
};
