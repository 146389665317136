import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "../../Button/Button";
import { ReactComponent as Check } from "../../../assets/icons/16x16/check.svg";

import styles from "./Success.module.scss";

export const Success: FC = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.iconContainer}>
                <Check className={styles.icon} />
            </div>
            <p className={styles.subHeader}>Dziękujemy</p>
            <p className={styles.header}>Płatność została zrealizowana</p>
            <p className={styles.supportText}>Możesz przejść do aplikacji</p>
            <Button
                fullWidth
                onClick={() => navigate("/matrixes")}
                variant={"secondary"}
                style={{ fontSize: "16px", height: "48px", fontWeight: 500, maxWidth: "312px", marginTop: "72px" }}
            >
                Przejdź
            </Button>
        </div>
    );
};
