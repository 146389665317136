// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepTwo_form__Ya56d {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 72px;
  width: 418px;
}

.StepTwo_title__DioXG {
  font-size: 24px;
  font-weight: 600;
}

.StepTwo_label__RLWJ4 {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #344054 !important;
  margin-bottom: 6px;
}

.StepTwo_textField__imrRW {
  width: 100%;
}

.StepTwo_submitButtonContainer__wtfWX {
  margin-top: 8px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ResetPasswordForm/StepTwo/StepTwo.module.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,YAAA;AAFJ;;AAKA;EACI,eAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,0BAAA;EACA,2BAAA;EACA,yBAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ;;AAKA;EACI,eAAA;EACA,WAAA;AAFJ","sourcesContent":["@use '../../../styles/responsive' as rwd; \r\n@use '../../../styles/colors' as colors; \r\n\r\n.form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 24px;\r\n    margin-top: 72px;\r\n    width: 418px;\r\n}\r\n\r\n.title {\r\n    font-size: 24px;\r\n    font-weight: 600;\r\n}\r\n\r\n.label {\r\n    font-size: 14px !important;\r\n    font-weight: 500 !important;\r\n    color: colors.$GRAY700 !important;\r\n    margin-bottom: 6px;\r\n}\r\n\r\n.textField {\r\n    width: 100%;\r\n}\r\n\r\n.submitButtonContainer {\r\n    margin-top: 8px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `StepTwo_form__Ya56d`,
	"title": `StepTwo_title__DioXG`,
	"label": `StepTwo_label__RLWJ4`,
	"textField": `StepTwo_textField__imrRW`,
	"submitButtonContainer": `StepTwo_submitButtonContainer__wtfWX`
};
export default ___CSS_LOADER_EXPORT___;
